import {createApp} from 'petite-vue'
import IBAN from 'iban'

(
    function ($) {
        var Checkout = {
            flow:      $('body').attr('data-flow'),
            customer: $('body').attr('data-customer'),
            context:  $('body').attr('data-context'),
            defaultDate: 10,

            autoFillFields: {
                city: '#city',
                street: '#street'
            },

            /**
             * Returns the default Ajax properties.
             *
             * @return Object.
             */
            AjaxProperties: function () {
                return {
                    url: '/assets/components/knpm/connector.php',
                    dataType: 'json',
                    cache: false
                };
            },

            /**
             * Update the zipcode field.
             *
             * @param HTMLObject element.
             */
            checkZipcodeForRoomnumber: function (element) {
                var $form = $(element.currentTarget).parents('form');
                var $roomField = $('[data-zipcode-checker-room]', $form);

                $.ajax($.extend(Checkout.AjaxProperties(), {
                    data: {
                        action: 'web/zipcode/get',
                        zipcode: {
                            zipcode: $('input[name="zipcode"]', $form).val(),
                            number: $('input[name="number"]', $form).val(),
                            addition: $('input[name="addition"]', $form).val(),
                            room: $('input[name="room"]', $form).val()
                        }
                    },
                    success: function (data) {
                        if (data.success) {
                            if (data.results.zipcodeHasRoom) {
                                $roomField.addClass('active');
                            } else {
                                $roomField.removeClass('active');
                            }
                        } else {
                            $roomField.removeClass('active');
                        }
                    }
                }));
            },

            /**
             * Update the product blocks.
             *
             * @param {Object} element.
             */
            onHandleCheckoutProduct: function (element) {
                var $product = $(element.currentTarget).parents('[data-checkout-product]');
                var $id = $('input[name="product"]', $product);
                var $status = $('input[name="status"]:checked', $product);

                Checkout.updateCheckoutMessage(null);

                if (Checkout.flow === 'create' || $status.val() === undefined) {
                    if (!$id.prop('checked')) {
                        Checkout.updateCheckoutProduct($product);
                    } else {
                        Checkout.removeCheckoutProduct($product);
                    }
                } else if ($status.val() === '+' || $status.val() === '-') {
                    Checkout.updateCheckoutProduct($product);
                } else {
                    Checkout.removeCheckoutProduct($product);
                }

                return false;
            },

            /**
             * Update the product blocks with a fake submit.
             *
             * @param {Object} element.
             */
            onHandleCheckoutProductSubmit: function (element) {
                $('form[name="form-product"]', $(element.currentTarget).parents('[data-checkout-product]')).submit();
            },

            /**
             * Add product to cart.
             *
             * @param {Object} $product.
             */
            updateCheckoutProduct: function ($product) {
                var id = $('input[name="product"]', $product).val();
                var category = $product.attr('data-checkout-product');
                var $status = $('input[name="status"]:checked', $product);
                var $products = $('[data-checkout-product="' + category + '"]').not($product);

                $.ajax($.extend(Checkout.AjaxProperties(), {
                    data: {
                        action: 'web/checkout/cart/update',
                        context_key: Checkout.context,
                        flow: Checkout.flow,
                        type: 'product',
                        method: 'update',
                        status: $status.val(),
                        category: category,
                        id: id
                    },
                    success: function (data) {
                        Checkout.updateCheckoutCallback($product, data, function () {
                            $product.addClass('active');

                            var $productForm = $('form[name="form-product"]', $product);

                            if ($productForm.length >= 1) {
                                $productForm.find('input[name="product"]').prop('checked', true);
                                $productForm.find('button[type="submit"]').addClass('active').attr('title', 'Toegevoegd!');
                                $productForm.find('button[type="submit"] span').html('Toegevoegd!');
                            }

                            if (Checkout.flow === 'create') {
                                $products.removeClass('active');
                                $products.find('input[name="product"]').prop('checked', false);
                                $products.find('button[type="submit"]').removeClass('active').attr('title', 'Toevoegen');
                                $products.find('button[type="submit"] span').html('Toevoegen');
                            } else {
                                $products.each(function (index, currentProduct) {
                                    var $currentProduct = $(currentProduct);

                                    if ($('input[name="status"][value="' + $status.val() + '"]:checked', $currentProduct).length >= 1) {
                                        $currentProduct.removeClass('active');
                                        $currentProduct.find('input[name="product"]').prop('checked', false);
                                        $currentProduct.find('button[type="submit"]').removeClass('active').attr('title', 'Toevoegen');
                                        $currentProduct.find('button[type="submit"] span').html('Toevoegen');

                                        $currentProduct.find('input[name="status"]').prop('checked', false).filter('[value=""]').prop('checked', true);
                                    }
                                });
                            }
                        });

                        Checkout.updateCheckoutProductAddons($product, category, id);
                    },
                    failure: function (data) {
                        Checkout.updateCheckoutCallback($product, category, data);
                        Checkout.updateCheckoutProductAddons($product, id);
                    }
                }));
            },

            /**
             * Remove product from cart.
             *
             * @param {Object} $product.
             */
            removeCheckoutProduct: function ($product) {
                var id = $('input[name="product"]', $product).val();
                var category = $product.attr('data-checkout-product');

                $.ajax($.extend(Checkout.AjaxProperties(), {
                    data: {
                        action: 'web/checkout/cart/update',
                        context_key: Checkout.context,
                        flow: Checkout.flow,
                        type: 'product',
                        method: 'remove',
                        category: category,
                        id: id
                    },
                    success: function (data) {
                        Checkout.updateCheckoutCallback($product, data, function () {
                            $product.removeClass('active');

                            var $productForm = $('form[name="form-product"]', $product);

                            if ($productForm.length >= 1) {
                                $productForm.find('input[name="product"]').prop('checked', false);
                                $productForm.find('button[type="submit"]').removeClass('active').attr('title', 'Toevoegen');
                                $productForm.find('button[type="submit"] span').html('Toevoegen');
                            }
                        });

                        Checkout.updateCheckoutProductAddons($product, category, id);
                    },
                    failure: function (data) {
                        Checkout.updateCheckoutCallback($product, data);
                        Checkout.updateCheckoutProductAddons($product, category, id);
                    }
                }));
            },

            /**
             * Update the product addon blocks.
             *
             * @param {Object} $product.
             * @param {String} category.
             */
            updateCheckoutProductAddons: function ($product, category) {
                var products = this.getCheckoutActiveProducts(category);

                $('[data-checkout-addon="' + category + '"]').each(function (index, addon) {
                    var $addon = $(addon);
                    var availableForProducts = $addon.attr('data-checkout-addon-products').split('|').filter(function (value) {
                        return value !== '';
                    });

                    if (availableForProducts.length === 0) {
                        $addon.addClass('available');
                    } else {
                        var active = availableForProducts.filter(function (product) {
                            return products.indexOf(parseInt(product, 10)) !== -1;
                        });

                        if (active.length >= 1) {
                            $addon.addClass('available');
                        } else {
                            if (Checkout.flow === 'create') {
                                $addon.removeClass('available active');
                                Checkout.removeCheckoutAddon($addon);
                            }
                            var $addonForm = $('> form[name="form-addon"]', $addon);

                            if ($addonForm.length >= 1) {
                                $addonForm.find('input[name="addon"], input[name="fake-addon"]').prop('checked', false);
                                $addonForm.find('button[type="submit"]').removeClass('active').attr('title', 'Toevoegen');
                                $addonForm.find('button[type="submit"] span').html('Toevoegen');

                                $addonForm.find('input[name^="property_"], select[name^="property_"]').attr('disabled', false);
                            }
                        }
                    }
                });
            },

            /**
             * Get all active products from a category.
             *
             * @param {String} category.
             */
            getCheckoutActiveProducts: function (category) {
                var products = [];

                $('[data-checkout-product="' + category + '"]').each(function (index, product) {
                    var $product = $(product);
                    var $id = $('input[name="product"]', $product);
                    var $status = $('input[name="status"]:checked', $product);

                    if ($id.prop('checked')) {
                        if (Checkout.flow !== 'create') {
                            if ($status.val() === '+') {
                                products.push(parseInt($id.val(), 10));
                            }
                        } else {
                            products.push(parseInt($id.val(), 10));
                        }
                    }
                });

                return products;
            },

            /**
             * Update the addon blocks.
             *
             * @param {Object} element.
             */
            onHandleCheckoutAddon: function (element) {
                var $addon = $(element.currentTarget).parents('[data-checkout-addon]:first');
                var $id = $('input[name="addon"]', $addon);
                var $status = $('input[name="status"]:checked', $addon);

                Checkout.updateCheckoutErrors(null, $addon);
                Checkout.updateCheckoutMessage(null);

                if (Checkout.flow === 'create' || $status.val() === undefined) {
                    if (!$id.prop('checked')) {
                        Checkout.updateCheckoutAddon($addon);
                    } else {
                        Checkout.removeCheckoutAddon($addon);
                    }
                } else if ($status.val() === '+' || $status.val() === '-') {
                    Checkout.updateCheckoutAddon($addon);
                } else {
                    Checkout.removeCheckoutAddon($addon);
                }

                return false;
            },

            /**
             * Update the addon blocks with a fake submit.
             *
             * @param {Object} element.
             */
            onHandleCheckoutAddonSubmit: function (element) {
                $('form[name="form-addon"]', $(element.currentTarget).parents('[data-checkout-addon]:first')).submit();
            },

            /**
             * Add addon to cart.
             *
             * @param {Object} $addon.
             */
            updateCheckoutAddon: function ($addon) {
                var id = $('input[name="addon"]', $addon).val();
                var category = $addon.attr('data-checkout-addon');
                var $status = $('input[name="status"]:checked', $addon);

                $.ajax($.extend(Checkout.AjaxProperties(), {
                    data: {
                        action: 'web/checkout/cart/update',
                        context_key: Checkout.context,
                        flow: Checkout.flow,
                        type: 'addon',
                        method: 'update',
                        status: $status.val(),
                        category: category,
                        id: id,
                        properties: Checkout.getAddonProperties($addon)
                    },
                    success: function (data) {
                        Checkout.updateCheckoutCallback($addon, data, function () {
                            $addon.addClass('active');

                            var $addonForm = $('> form[name="form-addon"]', $addon);
                            if ($addonForm.length >= 1) {
                                $addonForm.find('input[name="addon"], input[name="fake-addon"]').prop('checked', true);
                                $addonForm.find('button[type="submit"]').addClass('active').attr('title', 'Toegevoegd!');
                                $addonForm.find('button[type="submit"] span').html('Toegevoegd!');

                                $addonForm.find('input[name^="property_"], select[name^="property_"]').not('input[name^="property_keep_"]').attr('disabled', true);
                            }

                            if ($addon.data('checkout-addon') === 'phone' && $addonForm.find('input[name="addon"]').val() === '34') {
                                $('input[name="addon"][value="9"]').parents('[data-checkout-phone-unlimited]:first').removeClass('active');
                                $('input[name="addon"][value="9"]').prop('checked', false);
                                $('input[name="addon"][value="9"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"]')
                                    .removeClass('active')
                                    .attr('title', 'Toevoegen');
                                $('input[name="addon"][value="9"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"] span').html('Toevoegen');

                                $('input[name="addon"][value="10"]').parents('[data-checkout-phone-unlimited]:first').removeClass('active');
                                $('input[name="addon"][value="10"]').prop('checked', false);
                                $('input[name="addon"][value="10"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"]')
                                    .removeClass('active')
                                    .attr('title', 'Toevoegen');
                                $('input[name="addon"][value="10"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"] span').html('Toevoegen');

                                $('input[name="addon"][value="11"]').parents('[data-checkout-phone-unlimited]:first').removeClass('active');
                                $('input[name="addon"][value="11"]').prop('checked', false);
                                $('input[name="addon"][value="11"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"]')
                                    .removeClass('active')
                                    .attr('title', 'Toevoegen');
                                $('input[name="addon"][value="11"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"] span').html('Toevoegen');

                                $('input[name="addon"][value="56"]').parents('[data-checkout-phone-unlimited]:first').removeClass('active');
                                $('input[name="addon"][value="56"]').prop('checked', false);
                                $('input[name="addon"][value="11"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"]')
                                    .removeClass('active')
                                    .attr('title', 'Toevoegen');
                                $('input[name="addon"][value="56"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"] span').html('Toevoegen');
                            }

                            if ($addon.data('checkout-addon') === 'phone2nd' && $addonForm.find('input[name="addon"]').val() === '86') {
                                $('input[name="addon"][value="80"]').parents('[data-checkout-phone-unlimited]:first').removeClass('active');
                                $('input[name="addon"][value="80"]').prop('checked', false);
                                $('input[name="addon"][value="80"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"]')
                                    .removeClass('active')
                                    .attr('title', 'Toevoegen');
                                $('input[name="addon"][value="80"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"] span').html('Toevoegen');

                                $('input[name="addon"][value="81"]').parents('[data-checkout-phone-unlimited]:first').removeClass('active');
                                $('input[name="addon"][value="81"]').prop('checked', false);
                                $('input[name="addon"][value="81"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"]')
                                    .removeClass('active')
                                    .attr('title', 'Toevoegen');
                                $('input[name="addon"][value="81"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"] span').html('Toevoegen');

                                $('input[name="addon"][value="82"]').parents('[data-checkout-phone-unlimited]:first').removeClass('active');
                                $('input[name="addon"][value="82"]').prop('checked', false);
                                $('input[name="addon"][value="82"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"]')
                                    .removeClass('active')
                                    .attr('title', 'Toevoegen');
                                $('input[name="addon"][value="82"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"] span').html('Toevoegen');

                                $('input[name="addon"][value="90"]').parents('[data-checkout-phone-unlimited]:first').removeClass('active');
                                $('input[name="addon"][value="90"]').prop('checked', false);
                                $('input[name="addon"][value="90"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"]')
                                    .removeClass('active')
                                    .attr('title', 'Toevoegen');
                                $('input[name="addon"][value="90"]').parents('[data-checkout-phone-unlimited]:first').find('button[type="submit"] span').html('Toevoegen');
                            }

                            if ($addon.data('checkout-addon') === 'phone' && $addonForm.find('input[name="addon"]').val() === '47') {
                                $('input[name="addon"][value="22"]').parents('[data-checkout-addon]:first').removeClass('active');
                                $('input[name="addon"][value="22"]').prop('checked', false);
                                $('input[name="addon"][value="22"]').parents('[data-checkout-addon]:first').find('button[type="submit"]')
                                    .removeClass('active')
                                    .addClass('btn--grey-light')
                                    .attr('title', 'Toevoegen')
                                    .attr('disabled', true);
                                $('input[name="addon"][value="22"]').parents('[data-checkout-addon]:first').find('button[type="submit"] span').html('Toevoegen');

                                Checkout.updateCheckoutAddon($('input[name="addon"][value="48"]').parents('[data-checkout-addon]:first'));
                            }

                            if ($addon.data('checkout-addon') === 'phone2nd' && $addonForm.find('input[name="addon"]').val() === '86') {
                                $('input[name="addon"][value="83"]').parents('[data-checkout-addon]:first').removeClass('active');
                                $('input[name="addon"][value="83"]').prop('checked', false);
                                $('input[name="addon"][value="83"]').parents('[data-checkout-addon]:first').find('button[type="submit"]')
                                    .removeClass('active')
                                    .addClass('btn--grey-light')
                                    .attr('title', 'Toevoegen')
                                    .attr('disabled', true);
                                $('input[name="addon"][value="83"]').parents('[data-checkout-addon]:first').find('button[type="submit"] span').html('Toevoegen');

                                Checkout.updateCheckoutAddon($('input[name="addon"][value="87"]').parents('[data-checkout-addon]:first'));
                            }

                            if ($addon.data('checkout-addon') === 'phone' && $addonForm.find('input[name="addon"]').val() === '22') {
                                $('input[name="addon"][value="47"]').parents('[data-checkout-addon]:first').removeClass('active');
                                $('input[name="addon"][value="47"]').prop('checked', false);
                                $('input[name="addon"][value="47"]').parents('[data-checkout-addon]:first').find('button[type="submit"]')
                                    .removeClass('active')
                                    .addClass('btn--grey-light')
                                    .attr('title', 'Toevoegen')
                                    .attr('disabled', true);
                                $('input[name="addon"][value="47"]').parents('[data-checkout-addon]:first').find('button[type="submit"] span').html('Toevoegen');
                            }

                            if ($addon.data('checkout-addon') === 'phone2nd' && $addonForm.find('input[name="addon"]').val() === '83') {
                                $('input[name="addon"][value="86"]').parents('[data-checkout-addon]:first').removeClass('active');
                                $('input[name="addon"][value="86"]').prop('checked', false);
                                $('input[name="addon"][value="86"]').parents('[data-checkout-addon]:first').find('button[type="submit"]')
                                    .removeClass('active')
                                    .addClass('btn--grey-light')
                                    .attr('title', 'Toevoegen')
                                    .attr('disabled', true);
                                $('input[name="addon"][value="86"]').parents('[data-checkout-addon]:first').find('button[type="submit"] span').html('Toevoegen');
                            }

                            if ($addon.data('checkout-addon') === '3in1' && $addonForm.find('input[name="addon"]').val() === '60') {
                                var addonBasicInstallation = '[data-addon-id="14"]';
                                $(addonBasicInstallation).removeClass('active');
                                $(addonBasicInstallation).find('input[name="addon"], input[name="fake-addon"]').prop('checked', false);
                                $(addonBasicInstallation).find('button[type="submit"]').removeClass('active').attr('title', 'Toevoegen');
                                $(addonBasicInstallation).find('button[type="submit"] span').html('Toevoegen');
                                $(addonBasicInstallation).find('input[name^="property_"], select[name^="property_"]').attr('disabled', false);
                            }

                            if ($addon.data('checkout-addon') === '3in1' && $addonForm.find('input[name="addon"]').val() === '14') {
                                var addonFullInstallation = '[data-addon-id="60"]';
                                $(addonFullInstallation).removeClass('active');
                                $(addonFullInstallation).find('input[name="addon"], input[name="fake-addon"]').prop('checked', false);
                                $(addonFullInstallation).find('button[type="submit"]').removeClass('active').attr('title', 'Toevoegen');
                                $(addonFullInstallation).find('button[type="submit"] span').html('Toevoegen');
                                $(addonFullInstallation).find('input[name^="property_"], select[name^="property_"]').attr('disabled', false);
                            }

                            if ($addon.data('checkout-addon') === 'default' && $addonForm.find('input[name="addon"]').val() === '89') {
                                var addonBasicInstallation2 = '[data-addon-id="64"]';
                                $(addonBasicInstallation2).removeClass('active');
                                $(addonBasicInstallation2).find('input[name="addon"], input[name="fake-addon"]').prop('checked', false);
                                $(addonBasicInstallation2).find('button[type="submit"]').removeClass('active').attr('title', 'Toevoegen');
                                $(addonBasicInstallation2).find('button[type="submit"] span').html('Toevoegen');
                                $(addonBasicInstallation2).find('input[name^="property_"], select[name^="property_"]').attr('disabled', false);
                            }

                            if ($addon.data('checkout-addon') === 'default' && $addonForm.find('input[name="addon"]').val() === '64') {
                                var addonDiyInstallation = '[data-addon-id="89"]';
                                $(addonDiyInstallation).removeClass('active');
                                $(addonDiyInstallation).find('input[name="addon"], input[name="fake-addon"]').prop('checked', false);
                                $(addonDiyInstallation).find('button[type="submit"]').removeClass('active').attr('title', 'Toevoegen');
                                $(addonDiyInstallation).find('button[type="submit"] span').html('Toevoegen');
                                $(addonDiyInstallation).find('input[name^="property_"], select[name^="property_"]').attr('disabled', false);
                            }
                        });
                    },
                    failure: function (data) {
                        Checkout.updateCheckoutCallback($addon, data);
                    }
                }));
            },

            /**
             * Toggle options based on combinations
             */
            checkCombinations: function () {
                var combination = [];
                var combination2 = [];
                var allowedCombo = [9, 10, 11, 34];
                var allowedCombo2 = [79, 80, 81,85];
                $('[data-checkout-addon="phone"].active input[name="addon"]').each(function () {
                    if (allowedCombo.includes(parseInt($(this).val(), 10))) {
                        combination.push($(this).val());
                    }
                });

                if (combination.includes('9') && combination.includes('10') && combination.includes('11') && !combination.includes('34')) {
                    Checkout.updateCheckoutAddon($('input[name="addon"][value="34"]').parents('[data-checkout-addon="phone"]:first'));
                }

                if ((combination.includes('9') || combination.includes('10') || combination.includes('11')) && combination.includes('34')) {
                    Checkout.removeCheckoutAddon($('input[name="addon"][value="34"]').parents('[data-checkout-addon="phone"]:first'));
                }

                $('[data-checkout-addon="phone2nd"].active input[name="addon"]').each(function () {
                    if (allowedCombo2.includes(parseInt($(this).val(), 10))) {
                        combination2.push($(this).val());
                    }
                });

                if (combination2.includes('79') && combination2.includes('80') && combination2.includes('81') && !combination2.includes('85')) {
                    Checkout.updateCheckoutAddon($('input[name="addon"][value="85"]').parents('[data-checkout-addon="phone2nd"]:first'));
                }

                if ((combination2.includes('79') || combination2.includes('80') || combination2.includes('81') || combination2.includes('82')) && combination2.includes('85')) {
                    Checkout.removeCheckoutAddon($('input[name="addon"][value="85"]').parents('[data-checkout-addon="phone2nd"]:first'));
                }
            },

            /**
             * Remove addon from cart.
             *
             * @param {Object} $addon.
             */
            removeCheckoutAddon: function ($addon) {
                var id = $('input[name="addon"]', $addon).val();
                var category = $addon.attr('data-checkout-addon');

                $.ajax($.extend(Checkout.AjaxProperties(), {
                    data: {
                        action: 'web/checkout/cart/update',
                        context_key: Checkout.context,
                        flow: Checkout.flow,
                        type: 'addon',
                        method: 'remove',
                        category: category,
                        id: id,
                        properties: Checkout.getAddonProperties($addon)
                    },
                    success: function (data) {
                        Checkout.updateCheckoutCallback($addon, data, function () {
                            $addon.removeClass('active');

                            var $addonForm = $('> form[name="form-addon"]', $addon);

                            if ($addonForm.length >= 1) {
                                $addonForm.find('input[name="addon"], input[name="fake-addon"]').prop('checked', false);
                                $addonForm.find('button[type="submit"]').removeClass('active').attr('title', 'Toevoegen');
                                $addonForm.find('button[type="submit"] span').html('Toevoegen');

                                $addonForm.find('input[name^="property_"], select[name^="property_"]').attr('disabled', false);
                            }

                            if ($addon.data('checkout-addon') === 'phone' && $addonForm.find('input[name="addon"]').val() === '47') {
                                $('input[name="addon"][value="22"]').prop('checked', false);
                                $('input[name="addon"][value="22"]').parents('[data-checkout-addon]:first').find('button[type="submit"]')
                                    .removeClass('btn--grey-light')
                                    .attr('disabled', false);
                            }

                            if ($addon.data('checkout-addon') === 'phone2nd' && $addonForm.find('input[name="addon"]').val() === '86') {
                                $('input[name="addon"][value="83"]').prop('checked', false);
                                $('input[name="addon"][value="83"]').parents('[data-checkout-addon]:first').find('button[type="submit"]')
                                    .removeClass('btn--grey-light')
                                    .attr('disabled', false);
                            }

                            if ($addon.data('checkout-addon') === 'phone' && $addonForm.find('input[name="addon"]').val() === '22') {
                                $('input[name="addon"][value="47"]').prop('checked', false);
                                $('input[name="addon"][value="47"]').parents('[data-checkout-addon]:first').find('button[type="submit"]')
                                    .removeClass('btn--grey-light')
                                    .attr('disabled', false);
                            }

                            if ($addon.data('checkout-addon') === 'phone2nd' && $addonForm.find('input[name="addon"]').val() === '83') {
                                $('input[name="addon"][value="86"]').prop('checked', false);
                                $('input[name="addon"][value="86"]').parents('[data-checkout-addon]:first').find('button[type="submit"]')
                                    .removeClass('btn--grey-light')
                                    .attr('disabled', false);
                            }
                        });
                    },
                    failure: function (data) {
                        Checkout.updateCheckoutCallback($addon, data);
                    }
                }));
            },

            /**
             * Get addon form properties.
             *
             * @param {Object} $addon.
             * @return Object
             */
            getAddonProperties: function ($addon) {
                var properties = {};

                $.each($('form[name="form-addon"]', $addon).serializeArray(), function (key, value) {
                    if (value.name.match(/^property_/)) {
                        properties[value.name.replace('property_', '')] = value.value;
                    }
                });

                return properties;
            },

            /**
             * Update the redemption blocks.
             *
             * @param {Object} element.
             */
            onHandleCheckoutRedemption: function (element) {
                var $redemption = $(element.currentTarget).parents('[data-checkout-redemption]:first');
                var $id = $('input[name="redemption"]', $redemption);
                var $status = $('input[name="status"]:checked', $redemption);

                Checkout.updateCheckoutErrors(null, $redemption);
                Checkout.updateCheckoutMessage(null);

                if (Checkout.flow === 'create' || $status.val() === undefined) {
                    if (!$id.prop('checked')) {
                        Checkout.updateCheckoutRedemption($redemption);
                    } else {
                        Checkout.removeCheckoutRedemption($redemption);
                    }
                } else if ($status.val() === '+' || $status.val() === '-') {
                    Checkout.updateCheckoutRedemption($redemption);
                } else {
                    Checkout.removeCheckoutRedemption($redemption);
                }

                return false;
            },

            /**
             * Update the redemption blocks with a fake submit.
             *
             * @param {Object} element.
             */
            onHandleCheckoutRedemptionSubmit: function (element) {
                $('form[name="form-redemption"]', $(element.currentTarget).parents('[data-checkout-redemption]:first')).submit();
            },

            /**
             * Add redemption to cart.
             *
             * @param {Object} $redemption.
             */
            updateCheckoutRedemption: function ($redemption) {
                var id = $('input[name="redemption"]', $redemption).val();
                var $status = $('input[name="status"]:checked', $redemption);

                $.ajax($.extend(Checkout.AjaxProperties(), {
                    data: {
                        action: 'web/checkout/cart/update',
                        context_key: Checkout.context,
                        flow: Checkout.flow,
                        type: 'redemption',
                        method: 'update',
                        status: $status.val(),
                        id: id
                    },
                    success: function (data) {
                        Checkout.updateCheckoutCallback($redemption, data, function () {
                            $redemption.addClass('active');

                            var $redemptionForm = $('> form[name="form-redemption"]', $redemption);

                            if ($redemptionForm.length >= 1) {
                                $redemptionForm.find('input[name="redemption"]').prop('checked', true);
                                $redemptionForm.find('button[type="submit"]').addClass('active').attr('title', 'Toegevoegd!');
                                $redemptionForm.find('button[type="submit"] span').html('Toegevoegd!');
                            }
                        });
                    },
                    failure: function (data) {
                        Checkout.updateCheckoutCallback($redemption, data);
                    }
                }));
            },

            /**
             * Remove redemption from cart.
             *
             * @param {Object} $redemption.
             */
            removeCheckoutRedemption: function ($redemption) {
                var id = $('input[name="redemption"]', $redemption).val();

                $.ajax($.extend(Checkout.AjaxProperties(), {
                    data: {
                        action: 'web/checkout/cart/update',
                        context_key: Checkout.context,
                        flow: Checkout.flow,
                        type: 'redemption',
                        method: 'remove',
                        id: id
                    },
                    success: function (data) {
                        Checkout.updateCheckoutCallback($redemption, data, function () {
                            $redemption.removeClass('active');

                            var $redemptionForm = $('> form[name="form-redemption"]', $redemption);

                            if ($redemptionForm.length >= 1) {
                                $redemptionForm.find('input[name="redemption"]').prop('checked', false);
                                $redemptionForm.find('button[type="submit"]').removeClass('active').attr('title', 'Toevoegen');
                                $redemptionForm.find('button[type="submit"] span').html('Toevoegen');
                            }
                        });
                    },
                    failure: function (data) {
                        Checkout.updateCheckoutCallback($redemption, data);
                    }
                }));
            },

            /**
             * Update the checkout blocks callback.
             *
             * @param {Object} $element.
             * @param {Object} data.
             * @param {Function|Null} callback.
             * @return Boolean.
             */
            updateCheckoutCallback: function ($element, data, callback) {
                if (data.success) {
                    var status = true;

                    if (data.object && data.object.status) {
                        if (data.object.status === '+') {
                            status = true;
                        } else if (data.object.status === '-') {
                            status = false;
                        }
                    }

                    Checkout.updateCheckoutMessage(status, data.message, $element);

                    if (data.object && data.object.cart) {
                        Checkout.updateCheckoutCart($(data.object.cart));
                    }

                    if (typeof callback === 'function') {
                        callback.call();
                    }
                } else if (data.message) {
                    Checkout.updateCheckoutErrors(data.object, $element);
                    Checkout.updateCheckoutMessage(false, data.message, $element);
                } else {
                    Checkout.updateCheckoutMessage(false, 'Er is een onbekende fout opgetreden, probeer het nog een keer.', $element);
                }

                if (data.object.status === 'added' && $element.find('input[name="addon"]').val() === '34') {
                    Checkout.removeCheckoutAddon($('input[name="addon"][value="9"]').parents('[data-checkout-addon="phone"]:first'));
                    Checkout.removeCheckoutAddon($('input[name="addon"][value="10"]').parents('[data-checkout-addon="phone"]:first'));
                    Checkout.removeCheckoutAddon($('input[name="addon"][value="11"]').parents('[data-checkout-addon="phone"]:first'));
                } else if (data.object.status === 'added' && $element.find('input[name="addon"]').val() === '85') {
                    Checkout.removeCheckoutAddon($('input[name="addon"][value="79"]').parents('[data-checkout-addon="phone2nd"]:first'));
                    Checkout.removeCheckoutAddon($('input[name="addon"][value="80"]').parents('[data-checkout-addon="phone2nd"]:first'));
                    Checkout.removeCheckoutAddon($('input[name="addon"][value="81"]').parents('[data-checkout-addon="phone2nd"]:first'));
                } else if (data.object.status === 'added' && ($element.find('input[name="addon"]').val() !== '85' || $element.find('input[name="addon"]').val() !== '34')){
                    Checkout.checkCombinations();
                }

                return data.success;
            },

            /**
             * Update checkout message.
             *
             * @param {Boolean|Null} type.
             * @param {String} message.
             * @param {Object} $element.
             */
            updateCheckoutMessage: function (type, message, $element) {
                var $wrapper;

                if ($element === null) {
                    $wrapper = $('[data-checkout-message]');
                } else {
                    $wrapper = $('[data-checkout-message]', $element);
                }

                if (type === null) {
                    $wrapper.html('');
                } else {
                    $wrapper.html($('<div class="checkout-message checkout-message--' + (type === true ? 'success' : 'error') + '">'
                        + '<p>' + message + '</p>'
                    + '</div>').delay(3000).fadeOut(500)).show();
                }
            },

            /**
             * Update checkout errors.
             *
             * @param {Object} errors.
             * @param {Object} $element.
             */
            updateCheckoutErrors: function (errors, $element) {
                if (errors) {
                    $('input[name="status"][value=""]', $element).prop('checked', true);

                    $.each(errors, function (key) {
                        var $field = $('input[name="property_' + key + '"].form-control', $element);

                        if ($field.length >= 1) {
                            var $fieldError = $field.parent('.form-group').find('.help-block');

                            if ($fieldError.length >= 1) {
                                $fieldError.html(errors[key]);
                            } else {
                                $field.after($('<p class="help-block" />').html(errors[key]));
                            }

                            $field.addClass('error').parent('.form-group').addClass('has-error');
                        }
                    });
                } else {
                    $('.form-control.error', $element).removeClass('error');
                    $('.form-group.has-error', $element).removeClass('has-error');

                    $('.help-block', $element).remove();
                }
            },

            /**
             * Update checkout cart.
             *
             * @param {Object} $cart.
             */
            updateCheckoutCart: function ($cart) {
                $('[data-checkout-cart]').html($cart);

                if (Checkout.flow === 'update') {
                    $('td.price:contains("-")').html('[x]');
                    $('.update-monthly').show();
                    $('.create-monthly').hide();
                } else {
                    $('.create-monthly').show();
                    $('.update-monthly').hide();
                }

                $('tbody > tr > td.price:empty').parent().hide();
            },

            /**
             * Trigger checkout form.
             *
             * @param {Object} element.
             */
            onToggleExistingCustomer: function () {
                if ($('[data-toggle-existing] input').is(':checked') === true) {
                    $('body').attr('data-customer', 'existing');
                    $('[data-costs-activation]').hide();

                    var activationExist = $('[data-costs-activation] > td.price').text();
                    var activationIntExist = parseFloat(activationExist.replace(/€/g, ''));
                    var activationTotalExist = $('[data-costs-activation-total] > td.price').text();
                    var activationTotalIntExist = parseFloat(activationTotalExist.replace(/€/g, ''));
                    var activationCalcIntExist = activationTotalIntExist - activationIntExist;

                    $('[data-costs-activation-total] > td.price').text('€ ' + activationCalcIntExist.toFixed(2).replace('.', ','));
                } else if ($('body').attr('data-customer') === 'existing') {
                    $('body').attr('data-customer', 'new');
                    $('[data-costs-activation]').show();

                    var activationNew = $('[data-costs-activation] > td.price').text();
                    var activationIntNew = parseFloat(activationNew.replace(/€/g, ''));
                    var activationTotalNew = $('[data-costs-activation-total] > td.price').text();
                    var activationTotalIntNew = parseFloat(activationTotalNew.replace(/€/g, ''));

                    if (activationIntNew !== activationTotalIntNew) {
                        var activationCalcIntNew = activationTotalIntNew + activationIntNew;

                        $('[data-costs-activation-total] > td.price').text('€ ' + activationCalcIntNew.toFixed(2).replace('.', ','));
                    }
                }
            },

            /**
             * Trigger checkout form.
             *
             * @param {Object} element.
             */
            triggerCheckoutForm: function () {
                $('button[type="submit"]', '[data-checkout-form]').click();

                return false;
            },

            /**
             * Toggle info data popups
             *
             * @param HTMLObject element.
             */
            triggerInfoData: function (element) {
                var $info = $(element.currentTarget).toggleClass('active');

                $('.info-data').not($info).removeClass('active');

                $('body').unbind('click').bind('click', function () {
                    $info.removeClass('active');
                    $(this).unbind('click');
                });
            },

            /**
             * Trigger toggle blocks.
             *
             * @param array elements.
             */
            triggerToggleBlock: function (elements) {
                var $block;
                var $blockBtn;
                var i;

                for (i = 0; i < elements.length; i += 1) {
                    $block = $(elements[i]);
                    $blockBtn = $('<button role="button" class="toggle-block--btn" title="Meer informatie">Meer informatie</button>');

                    if ($.trim($block.html()).length) {
                        $block.wrapInner('<div class="toggle-block--content" />').prepend($blockBtn);
                    }
                }

                $('.toggle-block .toggle-block--btn').on('click', function () {
                    $(this).parents('.toggle-block').toggleClass('active').find('.toggle-block--content')
                        .stop()
                        .slideToggle();

                    return false;
                });
            },

            /**
             * Display billing fields if different billing address option is checked.
             */
            displayBillingFields: function () {
                if ($('[data-toggle-billing] input').is(':checked') === true) {
                    $('[data-field-billing]').show();
                } else {
                    $('[data-field-billing]').hide();
                }
            },

            /**
             * Display billing fields if different billing address option is checked.
             */
            displayBusinessFields: function () {
                var initialsLabel = $('label[for=initials]');
                var lnameLabel = $('label[for=lname]');
                var birthdateLabel = $('label[for=birthdate]');

                if ($('[data-toggle-businessclient] input').is(':checked') === true) {
                    $('[data-field-business]').show();
                    initialsLabel.text(initialsLabel.text().replace(' *', ''));
                    lnameLabel.text(lnameLabel.text().replace(' *', ''));
                    birthdateLabel.text(birthdateLabel.text().replace(' *', ''));
                } else {
                    $('[data-field-business]').hide();
                }
            },

            /**
             * Display partner fields if different partner option is checked.
             */
            displayPartnerFields: function () {
                if ($('[data-toggle-partner] input').is(':checked') === true) {
                    $('[data-field-partner]').show();
                } else {
                    $('[data-field-partner]').hide();
                }
            },

            /**
             * Handle address autofill for delivery and billing address.
             */
            addressAutofill: function (type) {
                var self = this;

                if (type === 'billing' && $('#billing_zipcode').length > 0 && $('#billing_zipcode').val().length > 0 && $('#billing_number').val().length) {
                    self.autoFillFields.city = '#billing_city';
                    self.autoFillFields.street = '#billing_street';

                    self.getAddress($('#billing_zipcode').val(), $('#billing_number').val());
                } else if ($('#zipcode').length > 0 && $('#zipcode').val() && $('#zipcode').val().length > 0 && $('#number').val().length) {
                    self.autoFillFields.city = '#city';
                    self.autoFillFields.street = '#street';

                    self.getAddress($('#zipcode').val(), $('#number').val(), $('#addition').val());
                }
            },

            /**
             * Handle address autofill for current address.
             */
            addressCurrentAutofill: function (type) {
                var self = this;

                if (type === 'current' && $('#property_current_zipcode').val() && $('#property_current_zipcode').val().length > 0 && $('#property_current_number').val().length) {
                    self.autoFillFields.city = '#property_current_city';
                    self.autoFillFields.street = '#property_current_street';

                    self.getAddress($('#property_current_zipcode').val(), $('#property_current_number').val());
                } else if (type === 'second-current' && $('#property_second_current_zipcode').val() && $('#property_second_current_zipcode').val().length > 0 && $('#property_second_current_number').val().length) {
                    self.autoFillFields.city = '#property_second_current_city';
                    self.autoFillFields.street = '#property_second_current_street';

                    self.getAddress($('#property_second_current_zipcode').val(), $('#property_second_current_number').val());
                }
            },

            /**
             * Retrieve address based on zipcode and housenumber.
             *
             * @param zipcode
             * @param number
             * @param addition
             */
            getAddress: function (zipcode, number, addition) {
                var self = this;

                $.ajax({
                    url: '/assets/components/site/connector.php',
                    data: {
                        action: 'web/address/get',
                        zipcode: zipcode,
                        housenumber: number,
                        addition: addition || ''
                    },
                    dataType: 'json',
                    cache: false,
                    success: function (data) {
                        self.fillAddress(data);
                    }
                });
            },

            /**
             * Fill the address fields using API results.
             *
             * @param address
             */
            fillAddress: function (address) {
                var self = this;

                if (address.city !== null && address.city.length > 0) {
                    $(self.autoFillFields.city).val(address.city);
                }

                if (address.street !== null && address.street.length > 0) {
                    $(self.autoFillFields.street).val(address.street);
                }
            },

            /**
             * Validate date input.
             *
             * @param element.
             */
            isValidDate: function (element) {
                var dateString = $(element.currentTarget).val();
                var regEx = /^\d{2}-\d{2}-\d{4}$/;

                if (!dateString.match(regEx) || dateString.match('00-00-0000')) {
                    $(element.currentTarget).css('border-color', 'red');
                    $(element.currentTarget).parent().addClass('has-error');
                    $('.btn--next').prop('disabled', true);
                    $('.help-block.date').removeClass('d-none');

                    return;
                }

                $('.help-block.date').addClass('d-none');
                $('.btn--next').prop('disabled', false);
                $(element.currentTarget).css('border-color', 'transparent');
            },

            changeInstallDate: function (element) {
                var $startingdateDatepicker = $('.startingdate_datepicker');
                var $installationDatepicker = $('.installation_datepicker');
                var next = 30;
                var date = new Date();
                if ($(element.currentTarget).val() !== 'other') {
                    next = $(element.currentTarget).val();
                    this.defaultDate = next;
                }

                date.setDate(date.getDate() + Number(next));

                var dateString = ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();

                if ($installationDatepicker.length >= 1) {
                    $installationDatepicker.attr('data-min-date', dateString);
                    $installationDatepicker.datepicker('destroy');
                }

                if ($startingdateDatepicker.length >= 1) {
                    $startingdateDatepicker.datepicker('destroy');
                }

                this.initDatePickers();
            },

            initDatePickers: function () {
                var $datepicker = $('.datepicker');
                var $birthdayDatepicker = $('.birthday_datepicker');
                var $startingdateDatepicker = $('.startingdate_datepicker');
                var $transferDatepicker = $('.transfer_datepicker');
                var $preferinstallDatepicker = $('.preferinstall_datepicker');
                var $installationDatepicker = $('.installation_datepicker');
                var dates = [];

                $.datepicker.setDefaults($.extend($.datepicker.regional.nl));

                if ($datepicker.length >= 1) {
                    $datepicker.datepicker({
                        changeMonth: false,
                        changeYear: false
                    });
                }

                if ($birthdayDatepicker.length >= 1) {
                    var currentYear = new Date().getFullYear();
                    $birthdayDatepicker.datepicker({
                        maxDate: '-18Y',
                        changeMonth: true,
                        changeYear: true,
                        yearRange: (currentYear - 100) + ':' + (currentYear),
                        onClose: function () {
                            $('[name="birthdate"]').trigger('keyup');
                        }
                    });
                }

                if ($startingdateDatepicker.length >= 1) {
                    $startingdateDatepicker.datepicker({
                        minDate: '+' + this.defaultDate + 'd'
                    });
                }

                if ($transferDatepicker.length >= 1) {
                    $transferDatepicker.datepicker({
                        minDate: '+15d',
                        maxDate: '+90d',
                        beforeShowDay: $.datepicker.noWeekends,
                        onSelect: function () {
                            this.dispatchEvent(new Event('input'));
                        }
                    });
                }

                if ($preferinstallDatepicker.length >= 1) {
                    $preferinstallDatepicker.datepicker({
                        minDate: '+10d'
                    });
                }

                if ($installationDatepicker.length >= 1) {
                    $installationDatepicker.datepicker({
                        changeMonth: true,
                        changeYear: true,
                        altField: $installationDatepicker.attr('data-alt-field'),
                        minDate: $installationDatepicker.attr('data-min-date'),
                        maxDate: $installationDatepicker.attr('data-max-date'),
                        yearRange: $installationDatepicker.attr('data-min-year') + ':' + $installationDatepicker.attr('data-max-year'),
                        beforeShowDay: function (date) {
                            var format = $.datepicker.formatDate('dd-mm-yy', date);

                            if ($.inArray(format, dates) !== -1 || dates.length === 0) {
                                return [false];
                            }

                            return [true];
                        },
                        onSelect: function () {
                            $('[name="installation_date"]').trigger('change');
                        }
                    });

                    $('select[name="installation_time"]').on('change', function () {
                        $.ajax({
                            url: '/assets/components/planning/connector.php',
                            data: {
                                action: 'web/getunavailabledates',
                                calendar: $(this).val(),
                                limit: 999
                            },
                            dataType: 'json',
                            cache: false,
                            success: function (data) {
                                dates = [];

                                if (data.success && data.results) {
                                    data.results.forEach(function (record) {
                                        dates.push(record.day + '-' + record.month + '-' + record.year);
                                    });
                                } else {
                                    /* eslint-disable */
                                    alert('Er is op dit moment geen beschikbaarheid, neem contact op met Kabelnoord voor verdere informatie.' + data.message);
                                    /* eslint-enable */
                                }

                                if ($installationDatepicker.datepicker('option', 'altField')) {
                                    $installationDatepicker.datepicker('setDate', null);
                                }

                                $installationDatepicker.datepicker('refresh');
                            },
                            error: function (jqXHR, textStatus, errorThrown) {
                                /* eslint-disable */
                                alert('Er iets misgegaan bij het ophalen van de beschikbaarheid, neem a.u.b. contact op met Kabelnoord, error: ' + errorThrown);
                                /* eslint-enable */
                            }
                        });
                    });
                }
            },

            formatValue: function (element) {
                var $input = $(element.currentTarget);
                var method = $input.data('format');
                var value = $input.val();

                switch (method) {
                case 'lcase':
                    value = value.toLowerCase();
                    break;
                case 'initials':
                    value = value.toUpperCase()
                        .replace(/ /g, '')
                        .split('.')
                        .join('')
                        .replace(/([a-zA-Z])/g, '$1.');
                    break;
                case 'ucfirst':
                    value = value.charAt(0).toUpperCase() + value.slice(1);
                    break;
                default:
                    break;
                }

                $input.val(value);
            },

            addValidationError: function (input) {
                $(input).addClass('error');
                $(input).parent().addClass('has-error');
                $('.checkout-wrapper button').prop('disabled', true);

                if ($(input).parents('.form-group').find('.help-block').length === 0) {
                    $(input).parents('.form-group').append($('<p class="help-block">' + $(input).attr('data-error') + '</p>'));
                }
            },

            onHandleCallBarring: function (input) {
                var barring = $(input.currentTarget).data('call-barring');
                $('[data-btn-call-barring="' + barring + '"]').removeClass('btn--grey').attr('disabled', false);
            },

            removeValidationError: function (input) {
                var liveValidationErrors = 0;

                $(input).parents('.form-group').removeClass('has-error').find('.help-block')
                    .remove();
                $(input).removeClass('error');

                $('[data-validate]').each(function (idx, element) {
                    if ($(element).parents('.form-group').hasClass('has-error')) {
                        liveValidationErrors++;
                    }
                });

                if (liveValidationErrors === 0) {
                    $('.checkout-wrapper button').prop('disabled', false);
                }
            },

            validate: function (element) {
                var input = $(element.currentTarget);
                var method = $(input).attr('data-validate');
                var ibannumber;

                switch (method) {
                case 'iban':
                    ibannumber = $(input).val().replace(/ /g, '').toUpperCase();
                    /* eslint-disable no-undef */
                    if (!IBAN.isValid(ibannumber)) {
                        this.addValidationError(input);

                        $(input).val(ibannumber);
                        return false;
                    }
                    /* eslint-enable no-undef */

                    $(input).val(ibannumber);

                    this.removeValidationError(input);
                    break;
                case 'phone':
                case 'mobile':
                    if ($(input).val().replace(/ /g, '').length < 10) {
                        this.addValidationError(input);
                        return false;
                    }

                    this.removeValidationError($('input[data-validate="phone"]'));
                    this.removeValidationError($('input[data-validate="mobile"]'));
                    break;
                case 'partner':
                    var self = this;
                    $(input).val($(input).val().toUpperCase());

                    var partnerCode = $(input).val();

                    if (partnerCode.length !== 0) {
                        $.ajax({
                            type: 'get',
                            url: '/assets/components/partners/check.php',
                            dataType: 'json',
                            data: {
                                partner: partnerCode
                            },
                            /* eslint-disable */
                            success: function (response) {
                                if (response.status !== 'error') {
                                    self.removeValidationError(input);
                                } else if (response.status === 'error') {
                                    self.addValidationError(input);
                                    return false;
                                }
                            }
                            /* eslint-enable */
                        });
                    } else {
                        self.removeValidationError(input);
                    }

                    break;
                case 'smartcard':
                    $(input).val($(input).val());

                    var smartcardNumber = $(input).val();

                    if (smartcardNumber.length !== 0) {
                        if (smartcardNumber.length !== 11) {
                            this.addValidationError(input);
                            return false;
                        }
                        this.removeValidationError(input);
                    }

                    this.removeValidationError(input);
                    break;
                case 'kvk':
                    var kvk = $(input).val();

                    if (kvk.length !== 8 || kvk === '00000000' || kvk === '12345678' || kvk === '87654321') {
                        this.addValidationError(input);
                        return false;
                    }
                    this.removeValidationError(input);
                    break;
                case 'btw':
                    var btw = $(input).val().toLowerCase();

                    if (btw.length < 14) {
                        this.addValidationError(input);
                        return false;
                    }

                    if (btw.slice(0, 2) !== 'nl') {
                        this.addValidationError(input);
                        return false;
                    }

                    if (btw.slice(11, 12) !== 'b') {
                        this.addValidationError(input);
                        return false;
                    }

                    this.removeValidationError(input);
                    break;
                default:
                    break;
                }

                return true;
            },

            closeMessage: function (element) {
                $(element.currentTarget).hide();
            },

            toggleCurrentPhoneNumberFields: function (element) {
                var input = $(element.currentTarget);

                if ($(input).val().includes('Ja')) {
                    $(input).parents('form').find('[data-show-current]').show();
                } else {
                    $(input).parents('form').find('[data-show-current]').hide();
                }
            },

            toggleOtherFieldVisibility: function (element) {
				var selectElement = $(element.currentTarget); 
				var otherFieldDiv = $('[data-show-other-field]'); 
				
				if (selectElement.val() === 'other') {
					otherFieldDiv[0].style.display = 'block';
				} else {
					otherFieldDiv[0].style.display = 'none';
				}
			},

            toggleOtherFieldVisibilitySecond: function (element) {
				var selectElement = $(element.currentTarget); 
				var otherFieldDiv = $('[data-show-other-field-second]'); 
				
				if (selectElement.val() === 'other') {
					otherFieldDiv[0].style.display = 'block';
				} else {
					otherFieldDiv[0].style.display = 'none';
				}
			},

            checkCurrentPhoneNumberFields: function () {
                var valueCpn = 0;
                var valueCpnCount = 0;

                if ($('[data-validation-cpn="33"]')) {
                    $('[data-validation-cpn="33"]').each(function () {
                        valueCpnCount += 1;

                        if ($(this).val() !== '') {
                            valueCpn += 1;
                        }
                    });

                    if (valueCpn === valueCpnCount) {
                        $('.cpn-error-33').hide();
                        $('[data-cpn-addon="33"]').removeAttr('disabled');
                    } else {
                        $('.cpn-error-33').show();
                        $('[data-cpn-addon="33"]').attr('disabled', 'disabled');
                    }
                }

                if ($('[data-validation-cpn="84"]')) {
                    $('[data-validation-cpn="84"]').each(function () {
                        valueCpnCount += 1;

                        if ($(this).val() !== '') {
                            valueCpn += 1;
                        }
                    });

                    if (valueCpn === valueCpnCount) {
                        $('.cpn-error-84').hide();
                        $('[data-cpn-addon="84"]').removeAttr('disabled');
                    } else {
                        $('.cpn-error-84').show();
                        $('[data-cpn-addon="84"]').attr('disabled', 'disabled');
                    }
                }
            },

            checkKeepPhone: function () {
                var keepPhone = $('[name="property_keep_current_phonenumber"]:checked');

                if ($('[name="product"][value="52"]').prop('checked') && keepPhone.length === 0) {
                    keepPhone.parents('.form-group').css('border', '2px solid red');
                    $('html, body').animate({
                        scrollTop: $('[name="property_keep_current_phonenumber"]').parents('.form-group').offset().top
                    }, 1000);

                    return false;
                }

                var keepPhone2 = $('[name="property_nee_second_keep_current_phonenumber"]:checked');
                if ($('[name="product"][value="76"]').prop('checked') && keepPhone2.length === 0) {
                    keepPhone2.parents('.form-group').css('border', '2px solid red');
                    $('html, body').animate({
                        scrollTop: $('[name="property_nee_second_keep_current_phonenumber"]').parents('.form-group').offset().top
                    }, 1000);

                    return false;
                }

                return true;
            },

            setForcedAddons: function () {
                $('.checkout-addons--item[data-forcetocart="1"]').each(function (index, addon) {
                    $(addon).find('form').trigger('submit');
                });
            },

            validateInstall: function () {                
                $('[name="check-transfer"]').removeClass('disabled');
                $('[name="check-transfer"]').prop('disabled', false);
            },

            /**
             * Function call on page load.
             */
            onPageLoad: function () {
                var self = this;
                var $zipCheckerRoom = $('[data-zipcode-checker-room]');

                self.displayBillingFields();
                self.displayBusinessFields();

                if ($('[data-autofill-billing-address]').length > 0) {
                    self.addressAutofill('delivery');
                    self.addressAutofill('billing');
                }

                if ($('[data-autofill-current-address]').length > 0) {
                    self.addressCurrentAutofill('current');
                }

                if ($('[data-autofill-second-current-address]').length > 0) {
                    self.addressCurrentAutofill('second-current');
                }

                self.displayPartnerFields();

                self.onToggleExistingCustomer();

                self.initDatePickers();

                self.setForcedAddons();

                if (Checkout.flow === 'update') {
                    $('td.price:contains("-")').html('[x]');
                    $('.update-monthly').show();
                    $('.create-monthly').hide();
                } else {
                    $('.create-monthly').show();
                    $('.update-monthly').hide();
                }

                if ($($zipCheckerRoom).length > 0) {
                    if ($('#room').val().length > 0) {
                        $($zipCheckerRoom).addClass('active');
                    } else {
                        $($zipCheckerRoom).removeClass('active');
                    }
                }

                $('tbody > tr > td.price:empty').parent().hide();

                Checkout.triggerToggleBlock($('.toggle-block'));

                if (($('[name="startingdate"]').length > 0 && !$('[name="startingdate"]').val()) ||
                    ($('[name="installation_time"]').length > 0 && !$('[name="installation_time"]').val())
                ) {
                    $('.checkout-pagination--wrapper_transfer button').addClass('disabled');
                }

                setTimeout(function () {
                    if ($('[name="birthdate"]').val()) {
                        $('[name="birthdate"]').trigger('keyup');
                    }
                }, 250);
            }
        };

        $(document).on({
            change: $.proxy(Checkout, 'validateInstall')
        }, 'input[name="startingdate"]');

        $(document).on({
            change: $.proxy(Checkout, 'validateInstall')
        }, '[name="installation_date"]');

        $(document).on({
            change: $.proxy(Checkout, 'onToggleExistingCustomer')
        }, '[data-toggle-existing] input');

        $(document).on({
            keyup: $.proxy(Checkout, 'checkZipcodeForRoomnumber')
        }, '[data-zipcode-checker] input:not([name="room"])');

        $(document).on({
            submit: $.proxy(Checkout, 'onHandleCheckoutProduct')
        }, '[data-checkout-product] form[name="form-product"]');

        $(document).on({
            change: $.proxy(Checkout, 'onHandleCheckoutProductSubmit')
        }, '[data-checkout-product] form[name="form-product"] input[name="status"]');

        $(document).on({
            submit: $.proxy(Checkout, 'onHandleCheckoutAddon')
        }, '[data-checkout-addon] form[name="form-addon"]');

        $(document).on({
            change: $.proxy(Checkout, 'onHandleCheckoutAddonSubmit')
        }, '[data-checkout-addon] form[name="form-addon"] input[name="status"], [data-checkout-addon-submit] label');

        $(document).on({
            change: $.proxy(Checkout, 'onHandleCallBarring')
        }, '[data-call-barring]');

        $(document).on({
            submit: $.proxy(Checkout, 'onHandleCheckoutRedemption')
        }, '[data-checkout-redemption] form[name="form-redemption"]');

        $(document).on({
            change: $.proxy(Checkout, 'onHandleCheckoutRedemptionSubmit')
        }, '[data-checkout-redemption] input[name="status"]');

        $(document).on({
            click: $.proxy(Checkout, 'triggerCheckoutForm')
        }, '[data-checkout-cart] .btn');

        $(document).on({
            click: $.proxy(Checkout, 'triggerInfoData')
        }, '.info-data');

        $(document).on({
            click: $.proxy(Checkout, 'checkKeepPhone')
        }, '[name="check-products"]');

        window.onload = function () {
            Checkout.onPageLoad();
        };

        $(document).on({
            change: $.proxy(Checkout, 'displayBillingFields')
        }, '[data-toggle-billing] input');

        $(document).on({
            change: $.proxy(Checkout, 'displayBusinessFields')
        }, '[data-toggle-businessclient] input');

        $(document).on({
            change: $.proxy(Checkout, 'displayPartnerFields')
        }, '[data-toggle-partner] input');

        $(document).on({
            change: $.proxy(Checkout, 'addressAutofill', 'billing')
        }, '[data-autofill-billing-address]');

        $(document).on({
            change: $.proxy(Checkout, 'addressCurrentAutofill', 'current')
        }, '[data-autofill-current-address]');

        $(document).on({
            change: $.proxy(Checkout, 'addressCurrentAutofill', 'second-current')
        }, '[data-autofill-second-current-address]');

        $(document).on({
            keyup: $.proxy(Checkout, 'isValidDate')
        }, '[name="birthdate"]');

        $(document).on({
            change: $.proxy(Checkout, 'formatValue')
        }, '[data-format]');

        $(document).on({
            change: $.proxy(Checkout, 'validate')
        }, '[data-validate]');

        $(document).on({
            change: $.proxy(Checkout, 'changeInstallDate')
        }, '[name="subscription_start"]');

        $(document).on({
            change: $.proxy(Checkout, 'toggleCurrentPhoneNumberFields')
        }, '[data-toggle-current]');

        $(document).on({
			change: $.proxy(Checkout, 'toggleOtherFieldVisibility')
		}, '#property_current_provider');

        $(document).on({
			change: $.proxy(Checkout, 'toggleOtherFieldVisibilitySecond')
		}, '#property_second_current_provider');

        $(document).on({
            click: $.proxy(Checkout, 'closeMessage')
        }, '[data-checkout-message]');

        $(document).on({
            keyup: $.proxy(Checkout, 'checkCurrentPhoneNumberFields')
        }, '[data-validation-cpn]');

        /* eslint-disable no-unused-expressions */
        window.addEventListener
            ? window.addEventListener('load', Checkout.onPageLoad(), false)
            : window.attachEvent && window.attachEvent('onload', Checkout.onPageLoad());

        if (typeof createApp !== 'undefined') {
            createApp({ $delimiters: ['<<', '>>'] }).mount();
        }
    }(jQuery)
);
