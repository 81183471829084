+function($) {

    if ($('.activate-smartcard').length > 0) {

        var NetAdminApi = {

            $connectorUrl            : '/assets/components/netadmin/connector.php',

            $smartcardButton         : 'button[data-smartcard-button]',
            $smartcardInput          : 'input[data-smartcard-input]',
            $smartcardInputContainer : 'div[data-smartcard-input-container]',

            $errorContainer          : 'p.help-block',
            $errorClass              : 'has-error',

            $smartcardStep1          : '#step1',
            $smartcardStep2          : '#step2',
            $smartcardStep3          : '#step3',
            $smartcardFailed         : '#failed',
            $smartcardFailedNonexisting : '#nonexisting',
            $smartcardFailedTimer    : '#timer',
            $smartcardFailedError    : '#error',
            $progressBar             : '.progress-bar',

            reactivateSmartcard : function(e) {
                var self = this,
                    smartcardNumber = $(self.$smartcardInput).val();

                e.preventDefault();

                if (smartcardNumber === '' || smartcardNumber.length !== 11) {
                    return false;
                } else {
                    $(self.$progressBar).removeClass('bg-warning').addClass('progress-bar-striped progress-bar-animated');
                }

                $(self.$errorContainer).html();
                $(self.$smartcardInputContainer).removeClass(self.$errorClass);

                $.ajax({
                    url: self.$connectorUrl,
                    method: 'post',
                    data: {
                        action : 'web/api',
                        method : 'reactivateSmartcard',
                        number : smartcardNumber
                    }
                }).success(function(response) {
                    switch (response.status) {
                        case 1 :
                            $(self.$smartcardStep1).hide();
                            $(self.$smartcardStep2).hide();
                            $(self.$smartcardStep3).hide();
                            $(self.$smartcardFailed).show();
                            $(self.$smartcardFailedNonexisting).show();
                            $(self.$smartcardFailedTimer).hide();
                            $(self.$smartcardFailedError).hide();
                            $(self.$smartcardInputContainer).addClass(self.$errorClass);
                            $(self.$progressBar).removeClass('progress-bar-striped progress-bar-animated');
                            break;
                        case 2 :
                            $(self.$smartcardStep1).hide();
                            $(self.$smartcardStep2).hide();
                            $(self.$smartcardStep3).hide();
                            $(self.$smartcardFailed).show();
                            $(self.$smartcardFailedNonexisting).hide();
                            $(self.$smartcardFailedTimer).show();
                            $(self.$smartcardFailedError).hide();
                            $(self.$smartcardInputContainer).addClass(self.$errorClass);
                            $(self.$progressBar).removeClass('progress-bar-striped progress-bar-animated');
                            break;
                        case 999 :
                            $(self.$smartcardStep1).hide();
                            $(self.$smartcardStep2).hide();
                            $(self.$smartcardFailed).show();
                            $(self.$smartcardFailedNonexisting).hide();
                            $(self.$smartcardFailedTimer).hide();
                            $(self.$smartcardFailedError).show();
                            $(self.$smartcardInputContainer).addClass(self.$errorClass);
                            $(self.$progressBar).removeClass('bg-success progress-bar-striped progress-bar-animated').addClass('bg-warning');
                            break;
                        default :
                            $(self.$smartcardStep1).hide();
                            $(self.$smartcardStep2).hide();
                            $(self.$smartcardStep3).show();
                            $(self.$smartcardFailed).hide();
                            break;
                    }
                });
            }
        };

        $(document).on({
            click : $.proxy(NetAdminApi, 'reactivateSmartcard')
        }, NetAdminApi.$smartcardButton);

    }

}(jQuery);