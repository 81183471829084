
(
    function ($) {
        var Site = {
            $window: $(window),
            $searchContainer: $('.header--search'),
            $btnTop: $('.btn-top'),
            $networkContainer: $('.network--status'),
            $lightBox: $('.lightbox'),
            $socialShare: $('.social-share'),
            $validateForm: $('[data-validate]'),
            $confirmFlow: $('[data-confirm-flow]'),
            $slider: $('.slider'),
            $filterCheckboxes: $('.channeloverview__list .filters-interactief input.filter-package'),
            $filterAddonsCheck: $('.channeloverview__list .addons-filters input.filter-addons'),
            allFilters: [],

            $autofillInitField: '[data-autofill]',
            $autofillFields: {
                zipcode: '#zipcode',
                housenumber: '#housenumber',
                street: '#street',
                city: '#city'
            },

            $autofillFieldsFilledOnce: {
                old_zipcode_alpha: false,
                old_zipcode_digits: false,
                old_housenumber: false,
                billing_zipcode_alpha: false,
                billing_zipcode_digits: false,
                billing_housenumber: false,
                zipcode_alpha: false,
                zipcode_digits: false,
                housenumber: false
            },

            $knidInitField: '[data-fillknid]',

            $installationTypeRadio: $('input[type=radio][name=installation_type]'),

            /**
             * Toggle the search form in the header.
             */
            toggleSearchForm: function () {
                this.$searchContainer.stop().slideToggle();
                $('.search-toggle').toggleClass('active');
                $('.search-form-input', this.$searchContainer).focus();

                return false;
            },

            changeContactMethod: function (element) {
                var value = $(element.currentTarget).find('option:selected').data('contact-method');
                $('[data-contact-method-field]').hide();
                $('[data-contact-method-field="' + value + '"]').show();
            },

            /**
             * Check the search form for an empty value.
             */
            checkSearchForm: function () {
                var $input = $('.search-form-input', this.$searchContainer);

                if ($input.val() === '' || $input.val() === $input.attr('placeholder')) {
                    /* eslint-disable */
                    alert($('form', this.$searchContainer).attr('data-alert'));
                    /* eslint-enable */
                    return false;
                }

                return true;
            },

            /**
             * Toggle navigation in the header.
             *
             * @param HTMLObject element.
             */
            toggleNav: function (element) {
                var $parentTarget = $(element.currentTarget).parents('.navigation').toggleClass('on');

                if (
                    $parentTarget.hasClass('navigation__fade-fullscreen')
                    || $parentTarget.hasClass('navigation__corner-fullscreen')
                    || $parentTarget.hasClass('navigation__slide-right')
                ) {
                    $('body').toggleClass('modal-open');
                }

                return false;
            },

            /**
             * Toggle sub navigation in the header.
             *
             * @param HTMLObject element.
             */
            toggleSubNav: function (element) {
                var $element = $(element.currentTarget);

                if ($element.parent('li').hasClass('active')) {
                    if ($element.parent('li').hasClass('on')) {
                        $element.next().stop().slideDown();
                    } else {
                        $element.next().stop().slideUp();
                    }
                } else {
                    $element.next().stop().slideToggle();
                }

                $element.parent('li').toggleClass('on');

                return false;
            },

            /**
             * Scroll to the top of the page.
             */
            scrollToTop: function () {
                $('html,body').animate({
                    scrollTop: 0
                });

                return false;
            },

            /**
             * Toggle the network bar in the header.
             *
             * @param HTMLObject element
             */
            onToggleNetwork: function (element) {
                $(element.currentTarget).parent('.network--status').toggleClass('active');
            },

            /**
             * Filter results by checkbox
             *
             */
            filterProducts: function () {
                var selectedFilters = {};
                var $filteredResults = $('.channeloverview__table__row.visible[data-products]');

                $('.channeloverview__overlay').show();

                if (!$(document.activeElement).hasClass('form-control__select')) {
                    $('.channeloverview__list .filters-interactief :checkbox:checked').each(function () {
                        if (!Object.prototype.hasOwnProperty.call(selectedFilters, this.name)) {
                            selectedFilters[this.name] = [];
                        }
                        selectedFilters[this.name].push(this.value);
                    });

                    if (Object.keys(selectedFilters).length > 0) {
                        $('.in-package, .in-package-header').hide();
                        // $('li[data-product]').hide();
                        $filteredResults.hide();
                    } else {
                        $('.in-package, .in-package-header').show();
                        // $('li[data-product]').show();
                        $filteredResults.show();
                    }

                    // loop over the selected filter name -> (array) values pairs
                    $.each(selectedFilters, function (name, filterValues) {
                        // AND Filtering
                        $('.channeloverview__table__row[data-products~="' + filterValues.toString() + '"].visible').show();
                        // $('li[data-product~="' + filterValues.toString() + '"]').show();
                    });
                }

                setTimeout(function () {
                    $('.channeloverview__overlay').hide();
                }, 800);
            },

            /**
             * Filter add ons.
             */
            filterAddons: function () {
                var selectedFilters = {};
                var $filteredResults = $('.channeloverview__table__row.visible[data-addons]');

                if (!$(document.activeElement).hasClass('form-control__select')) {
                    if ($('.filters-interactief').css('display') === 'none') {
                        $('.channeloverview__list .addons-filters :checkbox:checked').each(function () {
                            if (!Object.prototype.hasOwnProperty.call(selectedFilters, this.name)) {
                                selectedFilters[this.name] = [];
                            }
                            selectedFilters[this.name].push(this.value);
                        });

                        if (Object.keys(selectedFilters).length > 0) {
                            $filteredResults.hide();
                        } else {
                            $filteredResults.show();
                        }

                        // loop over the selected filter name -> (array) values pairs
                        $.each(selectedFilters, function (name, filterValues) {
                            $('.channeloverview__table__row.visible[data-addons~="' + filterValues.toString().replace(/ /g, '-') + '"]').show();
                        });
                    }
                }
            },

            /**
             * Filter results by select box
             *
             * @param: element
             */
            filterPackage: function (element) {
                var $filteredResults = $('.channeloverview__table__row[data-package]');
                var value = $(element.currentTarget).val();

                var $addonsrows = $filteredResults.filter(function () {
                    return $(this).data('package') === value;
                });

                $('.device-content').hide();
                $('.channeloverview__table__row[data-package]').hide().removeClass('visible').filter($addonsrows)
                    .show()
                    .addClass('visible');
                $('.channeloverview__list :checkbox').attr('checked', false);

                if (value === 'Basis') {
                    $('.load--filters').show();
                    $('.form-group__filter').hide();
                    $('.filters-basis').show();
                    $('.channeloverview__table__row .hidden-device').css('opacity', '0');
                    $('.channeloverview__table__row .hidden-frequency').hide();
                    $('.channeloverview__table__item--description').hide();
                } else if (value === 'Extra pakketten') {
                    $('.load--filters').show();
                    $('.form-group__filter').hide();
                    $('.filters-extra').show();
                    $('.channeloverview__table__row .hidden-device').css('opacity', '0');
                    $('.channeloverview__table__row .hidden-frequency').hide();
                    $('.channeloverview__table__item--description').hide();
                }  else if (value === 'Analoog') {
                    $('.load--filters').hide();
                    $('.form-group__filter').hide();
                    $('.channeloverview__table__row .hidden-device').css('opacity', '0');
                    $('.channeloverview__table__row .hidden-frequency').show();
                    $('.channeloverview__table__item--description').hide();
                } else if (value === 'Interactief') {
                    $('.load--filters').show();
                    $('.form-group__filter').hide();
                    $('.filters-interactief').show();
                    $('.channeloverview__table__row .hidden-device').css('opacity', '1');
                    $('.channeloverview__table__row .hidden-frequency').hide();
                    $('.channeloverview__table__item--description').hide();
                } else {
                    $('.channeloverview__table__row .hidden-device').css('opacity', '0');
                    $('.channeloverview__table__row .hidden-frequency').hide();
                    $('.channeloverview__table__item--description').hide();
                    $('.load--filters').hide();
                    $('.form-group__filter').hide();
                }

                if ($('.filters-basis').css('display') === 'none' && $('.filters-extra').css('display') === 'none') {
                    $('.addons-filters').hide();
                } else {
                    $('.addons-filters').show();
                }
            },

            /**
             * Get Channel filters
             */
            getChannelFilters: function () {
                $.ajax({
                    url: '/assets/components/knpm/connector.php',
                    data: {
                        action: 'web/channelpackage/filters/getlist'
                    },
                    dataType: 'json',
                    cache: false,
                    success: function (data) {
                        var filtercheckbox = '';

                        $.each(data, function (i) {
                            if ($.inArray(data[i].title, Site.allFilters) === -1) {
                                Site.allFilters.push(data[i].title);
                            }

                            filtercheckbox += '<div class="form-check">' +
                                             '<label for="property_' + data[i].id + '">' +
                                             '<input type="checkbox" class="form-check-input filter-package" id="property_' + data[i].id + '" name="property_' + data[i].id + '" value="' + data[i].title + '"/>' +
                                             '<i></i>' + data[i].title + ' <span class="info-data"><span>' + data[i].description + '</span></span>' +
                                             '</label>' +
                                             '</div>';
                        });

                        $('<div class="filters-interactief form-group form-group__filter">' + filtercheckbox + '</div>').insertAfter('.load--filters');
                    }
                });
            },

            /**
             * Get Channel overview items
             */
            getChannelData: function () {
                $.ajax({
                    url: '/assets/components/knpm/connector.php',
                    data: {
                        action: 'web/channelpackage/getlist'
                    },
                    dataType: 'json',
                    cache: false,
                    success: function (data) {
                        var tableRow = '';
                        var mobileClass = 'channeloverview__table__row--interactive';
                        var mobileInfo = '';
                        var channelDescription = '';
                        var channelDescriptionInfo = '';
                        var productNames = '';
                        var addonNames = '';
                        var filterSelect = '';
                        var filterCheckBasis = '';
                        var filterCheckExtra = '';
                        var productStandard = '';
                        var productComplete = '';
                        var productMaximaal = '';
                        var attributes = '';
                        var oddClass = '';
                        var tableItem = '';
                        var selected = '';
                        var selectedstyle = '';
                        var addonsTitlesExtra = [];
                        var addonsTitlesBasis = [];
                        var frequency = '';

                        $.each(data, function (i) {
                            selected = '';
                            selectedstyle = '';

                            if (i === 0) {
                                selected = 'selected';
                            } else {
                                selectedstyle = 1;
                            }

                            // Ignore basic from filter
                            if (data[i].id !== 4) {
                                filterSelect += '<option ' + selected + ' value="' + data[i].title + '">' + data[i].title + '</option>';
                            }

                            if (data[i].channels.length) {
                                $.each(data[i].channels, function (c) {
                                    oddClass = '';
                                    tableItem = '';
                                    productStandard = '';
                                    productComplete = '';
                                    productMaximaal = '';
                                    attributes = '';
                                    addonNames = '';
                                    productNames = '';
                                    mobileInfo = '';

                                    if (c % 2 === 0) {
                                        oddClass = '';
                                    } else {
                                        oddClass = 'channeloverview__table__row__odd';
                                    }

                                    if (data[i].channels[c].attributes) {
                                        attributes = '<ul class="channel__icon">';
                                        $.each(data[i].channels[c].attributes, function (a) {
                                            // var products = ['All'];
                                            // var attrs = data[i].channels[c].attributes[a];
                                            //
                                            // if (attrs.channel_attribute_product) {
                                            //     var prodAttr = attrs.channel_attribute_product;
                                            //     $.each(prodAttr.split(','), function (i, value) {
                                            //         if (value == 51) {
                                            //             products.push('Maximaal');
                                            //         } else
                                            //         if (value == 50) {
                                            //             products.push('Compleet');
                                            //         } else {
                                            //             products.push('Standaard');
                                            //         }
                                            //     });
                                            // }

                                            var title = data[i].channels[c].attributes[a].title;
                                            title = title.toLowerCase();
                                            title = title.split(' ').join('');
                                            attributes += '<li class="channel__icon__item channel__icon__item--' + title + '">' +
                                            '<span>' + data[i].channels[c].attributes[a].title + '</span></li>';
                                        });
                                        attributes += '</ul>';
                                    }

                                    if (data[i].channels[c].addons) {
                                        $.each(data[i].channels[c].addons, function (l) {
                                            if (data[i].title === 'Basis') {
                                                // eslint-disable-next-line max-len
                                                if ($.inArray(data[i].channels[c].addons[l].title, addonsTitlesBasis) === -1) {
                                                    addonsTitlesBasis
                                                        .push(data[i].channels[c].addons[l].title);
                                                }
                                            }

                                            if (data[i].title === 'Extra pakketten') {
                                                // eslint-disable-next-line max-len
                                                if ($.inArray(data[i].channels[c].addons[l].title, addonsTitlesExtra) === -1) {
                                                    addonsTitlesExtra
                                                        .push(data[i].channels[c].addons[l].title);
                                                }
                                            }
                                            addonNames += data[i].channels[c].addons[l].title.replace(/ /g, '-') + ' ';
                                        });
                                    }

                                    if (data[i].channels[c].products) {
                                        $.each(data[i].channels[c].products, function (a) {
                                            if (data[i].channels[c].products[a].active === 1) {
                                                if (data[i].channels[c].products[a].title === 'Standaard') {
                                                    productNames += data[i].channels[c].products[a].title + ' ';
                                                    productStandard = '<span class="in-package"></span>';
                                                }

                                                if (data[i].channels[c].products[a].title === 'Compleet') {
                                                    productNames += data[i].channels[c].products[a].title + ' ';
                                                    productComplete = '<span class="in-package"></span>';
                                                }

                                                if (data[i].channels[c].products[a].title === 'Maximaal') {
                                                    productNames += data[i].channels[c].products[a].title + ' ';
                                                    productMaximaal = '<span class="in-package"></span>';
                                                }
                                            }
                                        });
                                    }

                                    if (data[i].channels[c].display_description !== '') {
                                        channelDescriptionInfo = ' <span class="channel-info-data"></span>';
                                        channelDescription = '<div class="channeloverview__table__item--description""><div class="richtext"><h3>' + data[i].channels[c].title + '</h3><p class="channel-desc">' + data[i].channels[c].display_description + '</p></div></div>';
                                    } else {
                                        channelDescriptionInfo = '';
                                        channelDescription = '<div class="channeloverview__table__item--description""></div>';
                                    }

                                    if (data[i].title === 'Analoog') {
                                        if (data[i].channels[c].frequency > 0) {
                                            frequency = String(data[i].channels[c].frequency).replace(/\./g, ',') + ' MHz.';
                                        } else {
                                            frequency = '';
                                        }
                                    }

                                    tableItem += '<div class="channeloverview__table__item"><img src="' + data[i].channels[c].logo + '" ' +
                                                 'title="' + data[i].channels[c].title + '" class="img-responsive"></div>';

                                    tableItem += '<div class="channeloverview__table__item u-flex-grow2"><span>' + data[i].channels[c].title + channelDescriptionInfo + '</span></div>' +
                                            '<div class="channeloverview__table__item"><span>' + data[i].channels[c].channel + '</span></div>';

                                    if (data[i].title === 'Analoog') {
                                        tableItem += '<div class="channeloverview__table__item"><span>' + frequency + '</span></div>';
                                    }

                                    tableItem += '<div class="channeloverview__table__item u-flex-grow3 hidden-device">' + attributes + '</div>';

                                    tableItem += '<div class="channeloverview__table__item hidden-device">' + productStandard + '</div>' +
                                            '<div class="channeloverview__table__item hidden-device">' + productComplete + '</div>' +
                                            '<div class="channeloverview__table__item hidden-device">' + productMaximaal + '</div>';

                                    mobileInfo += '<div class="device-content">';

                                    if (attributes.length > 0) {
                                        mobileInfo += '<div class="device-content__item">' +
                                                     '<span class="device-content__title">Opties</span>' + attributes +
                                                     '</div>';
                                    }

                                    if (productStandard.length > 0) {
                                        mobileInfo += '<div class="device-content__item">' +
                                                     '<span class="device-content__title">Standaard</span>' + productStandard +
                                                     '</div>';
                                    }

                                    if (productComplete.length > 0) {
                                        mobileInfo += '<div class="device-content__item">' +
                                                     '<span class="device-content__title">Compleet</span>' + productComplete +
                                                     '</div>';
                                    }

                                    if (productMaximaal.length > 0) {
                                        mobileInfo += '<div class="device-content__item">' +
                                                     '<span class="device-content__title">Maximaal</span>' + productMaximaal +
                                                     '</div>';
                                    }

                                    mobileInfo += '</div>';

                                    if (selectedstyle === 1) {
                                        if (data[i].title !== 'Interactief') {
                                            mobileInfo = '';
                                            mobileClass = '';
                                        }

                                        tableRow += '<div style="display: none;" class="channeloverview__table__row ' + oddClass + '  ' + mobileClass + '" ' +
                                                    'data-addons="' + addonNames + '" ' +
                                                    'data-package="' + data[i].title + '" ' +
                                                    'data-products="' + productNames + '">' + tableItem + '</div>' + channelDescription + mobileInfo;
                                    } else {
                                        if (data[i].title !== 'Interactief') {
                                            mobileInfo = '';
                                            mobileClass = '';
                                        }

                                        tableRow += '<div class="channeloverview__table__row ' + mobileClass + ' visible ' + oddClass + '" ' +
                                                    'data-addons="' + addonNames + '" ' +
                                                    'data-package="' + data[i].title + '" ' +
                                                    'data-products="' + productNames + '">' + tableItem + '</div>' + channelDescription + mobileInfo;
                                    }
                                });
                            }
                        });

                        $.each(addonsTitlesBasis, function (key, value) {
                            filterCheckBasis += '<div class="form-check">' +
                                                '<label for="addons_' + key + 'b">' +
                                                '<input type="checkbox" class="form-check-input filter-addons" id="addons_' + key + 'b" name="addons_' + key + 'b" value="' + value + '"/>' +
                                                '<i></i>' + value + '</label>' +
                                                '</div>';
                        });

                        $.each(addonsTitlesExtra, function (key, value) {
                            filterCheckExtra += '<div class="form-check">' +
                                                '<label for="addons_' + key + 'e">' +
                                                '<input type="checkbox" class="form-check-input filter-addons" id="addons_' + key + 'e" name="addons_' + key + 'e" value="' + value + '"/>' +
                                                '<i></i>' + value + '</label>' +
                                                '</div>';
                        });

                        $('.addons-filters').append('<div class="filters-basis form-group form-group__filter">' + filterCheckBasis + '</div><div class="filters-extra form-group form-group__filter">' + filterCheckExtra + '</div>');
                        $('#viewchannels').append(filterSelect);
                        $(tableRow).insertAfter('.channeloverview__table__row--header');

                        if (data[0].title === 'Basis') {
                            $('.load--filters').show();
                            $('.form-group__filter').hide();
                            $('.filters-basis').show();
                            $('.channeloverview__table__row .hidden-device').css('opacity', '0');
                            $('.channeloverview__table__row .hidden-frequency').hide();
                            $('.channeloverview__table__item--description').hide();
                        } else if (data[0].title === 'Extra pakketten') {
                            $('.load--filters').show();
                            $('.form-group__filter').hide();
                            $('.filters-extra').show();
                            $('.channeloverview__table__row .hidden-device').css('opacity', '0');
                            $('.channeloverview__table__row .hidden-frequency').hide();
                            $('.channeloverview__table__item--description').hide();
                        } else if (data[0].title === 'Analoog') {
                            $('.load--filters').hide();
                            $('.form-group__filter').hide();
                            $('.channeloverview__table__row .hidden-device').css('opacity', '0');
                            $('.channeloverview__table__row .hidden-frequency').show();
                            $('.channeloverview__table__item--description').hide();
                        } else if (data[0].title === 'Interactief') {
                            $('.load--filters').show();
                            $('.form-group__filter').hide();
                            $('.filters-interactief').show();
                            $('.channeloverview__table__row .hidden-device').css('opacity', '1');
                            $('.channeloverview__table__row .hidden-frequency').hide();
                            $('.channeloverview__table__item--description').hide();
                        } else {
                            $('.load--filters').hide();
                            $('.form-group__filter').hide();
                            $('.channeloverview__table__row .hidden-device').css('opacity', '0');
                            $('.channeloverview__table__row .hidden-frequency').hide();
                            $('.channeloverview__table__item--description').hide();
                        }

                        if ($('.filters-basis').css('display') === 'none' && $('.filters-extra').css('display') === 'none') {
                            $('.addons-filters').hide();
                        } else {
                            $('.addons-filters').show();
                        }
                    }
                });
            },

            /**
             * Close the popup.
             */
            closePopup: function (e) {
                $('body').removeClass('popup-active');

                $(e.currentTarget).parents('.popup').fadeOut();

                return false;
            },

            /**
             * Toggle a FAQ answer.
             *
             * @param event
             */
            onToggleFaq: function (event) {
                var $container = $(event.currentTarget).parents('.faq--question');

                $container.find('.faq--question__content').slideToggle();

                $container.toggleClass('active');

                return false;
            },

            /**
            * Toggle a Highlight item.
            *
            * @param event
            */
            toggleHighlight: function (event) {
                if ($(window).width() < 768) {
                    if ($(event.currentTarget).hasClass('active')) {
                        $('.highlight--block.active').find('.highlight--content').removeClass('active');
                        $('.highlight--block.active').removeClass('active');
                    } else {
                        $('.highlight--block').removeClass('active');
                        $(event.currentTarget).addClass('active');
                        $('.highlight--block').find('.highlight--content').removeClass('active');
                        $(event.currentTarget).find('.highlight--content').addClass('active');
                    }

                    return false;
                }

                return true;
            },

            toggleHighlightStopPropagation: function (event) {
                event.stopPropagation();
            },

            /**
             * Retrieve FAQ results based on search term.
             *
             * @param e
             */
            faqFilterResults: function (e) {
                var self = this;
                var filter = $(e.currentTarget).val();
                var resource = $('[data-faq-resource]').data('faq-resource');
                var faqDiv = $('.faq');

                // Add loading class.
                $(faqDiv).addClass('loading');

                $.ajax({
                    type: 'POST',
                    url: '/assets/components/site/connector.php',
                    data: {
                        action: 'web/faq/getlist',
                        search: filter,
                        resource: resource
                    },
                    context: self,
                    dataType: 'html',
                    success: function (result) {
                        $('[data-faq-results]').html(result);

                        // Remove loading class.
                        setTimeout(function () {
                            $(faqDiv).removeClass('loading');
                        }, 500);
                    }
                });
            },

            /**
             * Clear FAQ search.
             */
            faqClearSearch: function () {
                var self = this;

                $('[data-faq-filter]').val('');

                self.faqFilterResults('[data-faq-filter]');
            },

            /**
             * Toggle social share.
             *
             * @param HTMLObject element.
             */
            toggleSocialShare: function (element) {
                $(element.currentTarget).parents('.social-share').toggleClass('active');

                return false;
            },

            /**
             * Copy share URL to clipboard.
             *
             * @param HTMLObject element.
             */
            copyToClipboard: function (element) {
                var copy = false;
                var $element = $(element.currentTarget);

                $element.select();

                try {
                    copy = document.execCommand('copy');
                } catch (err) {
                    copy = false;
                }

                if (copy) {
                    $element.addClass('active').val($element.attr('data-success'));
                } else {
                    $element.removeClass('active');
                }
            },

            /**
             * Update the zipcode field.
             *
             * @param HTMLObject element.
             */
            updateZipcode: function (element) {
                var $form = $(element.currentTarget).parents('form');
                var prefix = $(element.currentTarget).attr('name')
                    .replace('digits', '')
                    .replace('alpha', '')
                    .replace(/_$/, '');
                var $digits;
                var $alpha;

                $digits = $('input[name="' + prefix + '_digits"]', $form);
                $alpha  = $('input[name="' + prefix + '_alpha"]', $form);

                $('input[name="' + prefix + '"]', $form).val($digits.val() + ' ' + $alpha.val());
            },

            /**
             * Update the address fields.
             *
             * @param HTMLObject element.
             */
            updateAddress: function (element) {
                var $element = $(element.currentTarget);

                $('[data-field="' + $(element.currentTarget).attr('name') + '"]', $element.parents('form')).html($element.val());
            },

            /**
             * Autofill address fields.
             *
             * @param element
             */
            autofillAddress: function (element) {
                var $element            = $(element.currentTarget);
                var prefix              = $element.attr('data-autofill');
                var zipcode             = $('input[name="' + prefix + 'zipcode"]').val().replace(' ', '');
                var housenumber         = $('input[name="' + prefix + 'housenumber"]').val().replace(' ', '');
                var housenumberAddon    = $('input[name="' + prefix + 'housenumber_addon"]').val().replace(' ', '');
                var keys                = ['street', 'city'];

                if ($('.newsletter-check').length > 0) {
                    zipcode = $('#' + prefix + 'zipcode_digits').val().replace(' ', '') + ' ' + $('#' + prefix + 'zipcode_alpha').val().replace(' ', '');
                    $('#zipcode').val(zipcode);
                }

                if (zipcode === '' || housenumber === '') {
                    return false;
                }

                $.ajax({
                    url         : '/assets/components/site/connector.php',
                    data        : {
                        action      : 'web/address/get',
                        zipcode     : zipcode,
                        housenumber : housenumber,
                        addition    : housenumberAddon
                    },
                    dataType    : 'json',
                    cache       : false,
                    success     : function (data) {
                        var $zipcodeInput = $('input[name="' + prefix + 'zipcode_digits"]').parents('.form-group');

                        if ($zipcodeInput.length) {
                            if ($zipcodeInput.find('.help-block').length === 0) {
                                $zipcodeInput.append($('<p class="help-block"></p>').hide());
                            }

                            if (data.error) {
                                $zipcodeInput.addClass('has-error').find('.help-block').show().text(data.error.message);
                            } else {
                                $zipcodeInput.removeClass('has-error').find('.help-block').hide();
                            }
                        }

                        if (data.status === 200) {
                            keys.forEach(function (key) {
                                var $input  = $('input[name="' + prefix + key + '"]');
                                var $text   = $('[data-field="' + prefix + key + '"]');

                                if ($input.length) {
                                    $input.val(data[key] || '').removeClass('error').addClass('valid');
                                    $input.parents('.form-group').removeClass('has-error').find('label.error').hide();
                                }

                                if ($text.length) {
                                    $text.text(data[key] || '').parent().show();
                                }
                            });

                            if ($zipcodeInput.length) {
                                $zipcodeInput.parents('.form-group').find('.help-block').remove();
                            }
                        }
                    }
                });

                return true;
            },

            /**
             * Open cobrowser chatbox between 8 & 17.00.
             */
            openChat: function () {
                var start =  8 * 60 + 0;
                var end   = 17 * 60 + 0;
                var now = new Date();
                var time = now.getHours() * 60 + now.getMinutes();

                if (time >= start && time < end) {
                    $('.app-block').slideToggle({
                        direction: 'up'
                    }, 400);
                }
                return false;
            },

            /**
             * Open Zender overview window
             */
            openZenderWindow: function () {
                $('body').addClass('channeloverview-active');
                $('.channeloverview').addClass('channeloverview__open');
            },

            /**
             * Close Zender overview window
             */
            closeZenderWindow: function () {
                $('body').removeClass('channeloverview-active');
                $('.channeloverview').removeClass('channeloverview__open');
            },

            /**
             * Disable button on click
             * @param element
             */
            disableButton: function (element) {
                var $element = $(element.currentTarget);
                $element.find('button[type="submit"]').addClass('disabled');
            },

            /**
             * Toggle more info zender
             */
            moreInfoZender: function (element) {
                var $element = $(element.currentTarget);

                if ($(window).width() < 991) {
                    if ($element.data('package') === 'Interactief') {
                        if ($element.hasClass('active')) {
                            $element.removeClass('active');

                            $element.next().slideUp().next()
                                .slideUp();
                        } else {
                            $element.addClass('active');

                            $element.next()
                                .slideDown().next()
                                .slideDown();
                        }
                    }
                } else {
                    $('.device-content').hide();
                }
            },

            /**
             * Show channel description
             */
            toggleChannelDescription: function (element) {
                var $element = $(element.currentTarget);
                $element.parents('.channeloverview__table__row').next().toggle();
            },

            /**
             * Show additional input field for other device.
             */
            toggleLiveTVAppOther: function () {
                var $additionalOther = $('#additional_other');
                $additionalOther.toggle();
                if (($additionalOther).is(':hidden')) {
                    $('#other').val('');
                }
            },

            /**
             * Show step 2 of activate smartcard form.
             */
            toggleActivateSmartcardStep2: function (e) {
                e.preventDefault();
                $('#step1').hide();
                $('#step2').show();
                $('#step3').hide();
                $('#failed').hide();
            },

            /**
             * Show step 2 of activate smartcard form.
             */
            toggleActivateSmartcardStep1: function (e) {
                e.preventDefault();
                $('#step1').show();
                $('#step2').hide();
                $('#step3').hide();
                $('#failed').hide();
            },

            /**
             * Check old smartcard.
             */
            checkOldSmartcard: function () {
                var zipCode = $('#zipcode').val();
                if (zipCode.length < 1) {
                    zipCode = $('#zipcode_digits').val() + ' ' + $('#zipcode_alpha').val();
                }

                $('.validate-sc').removeClass('has-error');
                $('.help-block').hide();
                $('[name="oldsmartcard-submit"]').attr('disabled', true);

                var houseNumber = $('#housenumber').val();
                var houseAddition = $('#housenumber_addition').val();
                var smartcard = $('#smartcard').val();
                if (smartcard.length === 11 && houseNumber.length !== 0) {
                    $.ajax({
                        type: 'get',
                        url: '/assets/components/oldsmartcards/check.php',
                        dataType: 'json',
                        data: {
                            zip_code: zipCode,
                            house_number: houseNumber,
                            house_addition: houseAddition,
                            smartcard: smartcard
                        },
                        success: function (response) {
                            if (response.status !== 'error') {
                                $('.validate-sc').removeClass('has-error');
                                $('.help-block').hide();
                                $('[name="oldsmartcard-submit"]').attr('disabled', false);
                            } else {
                                $('.validate-sc').addClass('has-error');
                                $('.help-block').show();
                                $('[name="oldsmartcard-submit"]').attr('disabled', true);
                            }
                        }
                    });
                }
            },

            onInitToggleBlocks: function () {
                $('[data-toggle-block]').each(function (index, item) {
                    var $item = $(item);
                    var $title = $('[data-toggle-block-title]', $item);

                    $title.append($('<button role="button" title="' + $title.text() + '" />').html($title.text()).on('click', function () {
                        if ($item.hasClass('active')) {
                            $('[data-toggle-block-content]', $item).slideUp('slow', function () {
                                $(item).removeClass('active');
                            });
                        } else {
                            $('[data-toggle-block-content]', $item).slideDown('slow', function () {
                                $(item).addClass('active');
                            });
                        }

                        return false;
                    }));
                });
            },

            onInitCheckMultiple: function () {
                $('.form-check-multiple .form-check-multiple__container').each(function (index, item) {
                    var $item = $(item);
                    var $title = $('<span class="data-title" />');

                    $('input', $item).on('mouseover', function () {
                        var value = $(this).val();

                        if (value === '+') {
                            $title.html('Voeg dienst toe aan mijn abonnement');
                        } else if (value === '-') {
                            $title.html('Haal dienst uit mijn abonnement');
                        } else {
                            $title.html('');
                        }
                    });

                    $item.append($title);
                });
            },

            /**
             * Toggle the flow confirm switch.
             */
            onToggleConfirmFlow: function () {
                this.$confirmFlow.toggleClass('active');
                /* eslint-disable no-restricted-globals */
                history.pushState('', document.title, window.location.pathname + window.location.search);
                /* eslint-enable no-restricted-globals */

                return false;
            },

            /**
             * Check old KNID.
             *
             * @param element
             */
            checkKnid: function (element) {
                var $element            = $(element.currentTarget);
                var prefix              = $element.attr('data-fillknid');
                var zipcode             = $('input[name="' + prefix + 'zipcode"]').val().replace(' ', '');
                var housenumber         = $('input[name="' + prefix + 'housenumber"]').val().replace(' ', '');
                var housenumberAddon    = $('input[name="' + prefix + 'housenumber_addon"]').val().replace(' ', '');

                $.ajax({
                    url         : '/assets/components/knpm/connector.php',
                    data        : {
                        action      : 'web/zipcode/detail',
                        zipcode     : zipcode,
                        number      : housenumber,
                        addition    : housenumberAddon,
                        room        : ''
                    },
                    dataType    : 'json',
                    cache       : false,
                    success     : function (data) {
                        if (data.status !== 'error') {
                            $('input[name="' + prefix + 'knid"]').val(data.gid);
                            $('input[name="' + prefix + 'region"]').val(data.provincie);
                        } else {
                            $('input[name="' + prefix + 'knid"]').val('');
                            $('input[name="' + prefix + 'region"]').val('');
                        }
                    }
                });

                return true;
            },

            validatePhoneInput: function (element) {
                var $netNumbers = [
                    '010','013','015','020','023','024','026','030','033','035','036','038','040','043','045','046','050','053','055','058','070','071','072','073','074','075','076','077','078','079','085','088','097'
                ];
                var phoneNumber = $(element.currentTarget).val();
                // Remove any non-digit characters from the input
                phoneNumber = phoneNumber.replace(/\D/g, '');

                // Split the phone number into groups
                var areaCode = phoneNumber.substr(0, 3);
                var subscriberNumber = phoneNumber.substr(3);

                if (!$netNumbers.includes(areaCode)) {
                    // Split the phone number into groups
                    var areaCode = phoneNumber.substr(0, 4);
                    var subscriberNumber = phoneNumber.substr(4);
                }

                if ($(element.currentTarget).attr('name') === 'mobile') {
                    var areaCode = phoneNumber.substr(0, 2);
                    var subscriberNumber = phoneNumber.substr(2);
                }

                // Format the phone number
                var formattedNumber = areaCode + '-' + subscriberNumber;

                $(element.currentTarget).val(formattedNumber);
            },

            validateBirthdayInput: function (element) {
                var key = element.charCode || element.keyCode || 0;
                var date = $(element.currentTarget).val();

                // Skip backspace / tab characters
                if (key !== 8 && key !== 9) {
                    if (date.length === 2 && !date.includes('-', 1)) {
                        date = date + '-';
                    }
                    if (date.length === 5 && !date.includes('-', 4)) {
                        date = date + '-';
                    }
                }

                // Replace mulitple dashes with one
                date = date.replaceAll('--', '-');

                $(element.currentTarget).val(date);
            },

            
            disableConfirmButton: function (element) {
                var $button = $(element.currentTarget);
            
                $button.addClass('disabled');

                return true;
            },

            /**
             * Function call on page load
             */
            onPageLoad: function () {
                var self = this;
                var hash = '';
                var start =  8 * 60 + 0;
                var end   = 17 * 60 + 0;
                var now = new Date();
                var time = now.getHours() * 60 + now.getMinutes();

                if (time >= start && time < end) {
                    $('.app-block_open').show();

                    if ($(window).width() < 767) {
                        $('.app-block_open.desktop').hide();
                    }
                }

                $(this.$autofillInitField).each(function (index, element) {
                    var $element = $(element);

                    if ($element.attr('name').search('street') !== -1) {
                        $element.trigger('change');
                    }
                });

                // $('[name="street"], [name="city"]').trigger('change');

                if ($('.channeloverview-template').length !== 0) {
                    Site.getChannelFilters();
                    Site.getChannelData();
                }

                $('a[href*="#"]').each(function () {
                    var url = $(this).attr('href');
                    var arr = url.split('#');
                    hash = arr[1];
                    if (hash.length && hash === 'zenderaanbod') {
                        $(this).addClass('btn--zenderview');
                        Site.getChannelFilters();
                        Site.getChannelData();
                    }

                    if ($(this).hasClass('hash')) {
                        $(this).attr('href', this.hash);
                    }
                }).not('.lightbox').on('click', function () {
                    var $element = $(this.hash);

                    if ($element.length) {
                        $('html, body').animate({
                            scrollTop: $element.offset().top
                        }, 500);

                        return false;
                    }

                    return true;
                });

                if (self.$lightBox.length) {
                    self.$lightBox.nivoLightbox({
                        afterHideLightbox: function () {
                            $('.nivo-lightbox-content').empty();
                        },
                        afterShowLightbox: function () {
                            var $recaptcha = $('.nivo-lightbox-content .g-recaptcha');

                            if ($recaptcha && grecaptcha) { // eslint-disable-line
                                $recaptcha.empty();

                                grecaptcha.render($recaptcha[0], { // eslint-disable-line
                                    sitekey: $recaptcha.attr('data-sitekey')
                                });
                            }
                        }
                    });
                }

                if (self.$validateForm.length) {
                    self.$validateForm.validate({
                        focusCleanup: false,
                        onkeyup: true
                    });

                    self.$validateForm.find('[data-rule-required]').blur(function () {
                        $(this.form).validate().element(this);
                    });

                    self.$validateForm.find('.datepicker').datepicker().on('change', function () {
                        var formDatepicker = $(this).parents(self.$validateForm);
                        $(formDatepicker).validate().element(this);  // triggers the validation test
                    });
                }

                if (self.$confirmFlow.length) {
                    if (window.location.hash) {
                        hash = window.location.hash.substring(1);

                        if (hash === 'confirm-flow') {
                            self.$confirmFlow.toggleClass('active');
                        }
                    }
                }

                if (self.$socialShare.length) {
                    if (window.location.hash) {
                        hash = window.location.hash.substring(1);

                        if (hash === 'mail-popup') {
                            if ($('.has-error', '#mail-popup').length !== 0) {
                                $('#gtm-share-email').trigger('click');
                            }
                        }
                    }
                }

                if ($('[data-contact-method="select]').length) {
                    $('[data-contact-method="select]').trigger('change');
                }

                if (self.$slider.length) {
                    self.$slider.owlCarousel({
                        items: 1,
                        animateOut: 'fadeOut',
                        autoplay: true,
                        autoplayTimeout: 3500,
                        autoplayHoverPause: true,
                        loop: true,
                        nav: true
                    });
                }

                $(window).scroll(function () {
                    if ($(window).scrollTop > $(window).height()) {
                        self.$btnTop.fadeIn();
                    } else {
                        self.$btnTop.fadeOut();
                    }
                });

                this.onInitToggleBlocks();
                this.onInitCheckMultiple();

                // Prevent double submit contactform.
                $('#contact-submit').on('click', function () {
                    $(this).html('Een moment geduld..').addClass('disabled');
                });

                if ($('#report-relocation').length) {
                    var $installation = $('#installation_details').hide();

                    this.$installationTypeRadio.on('change', function (e) {
                        if ($(e.currentTarget).val() === 'Doe-het-zelf Installatie') {
                            $installation.slideUp('500');
                        } else {
                            $installation.slideDown('500');
                        }
                    });
                }
            }
        };
        

        // $(document).on({
        //     submit: $.proxy(Site, 'disableButton')
        // }, 'form');

        $(document).on({
            click: $.proxy(Site, 'disableConfirmButton')
        }, '[name="check-confirm"]');
        

        $(document).on({
            change: $.proxy(Site, 'toggleLiveTVAppOther')
        }, '.check_android_other');

        $(document).on({
            click: $.proxy(Site, 'toggleActivateSmartcardStep1')
        }, '.activate-smartcard-step1');

        $(document).on({
            click: $.proxy(Site, 'toggleActivateSmartcardStep2')
        }, '.activate-smartcard-step2');

        $(document).on({
            keyup: $.proxy(Site, 'checkOldSmartcard')
        }, '.oldsmartcard');

        $(document).on({
            click: $.proxy(Site, 'toggleSearchForm')
        }, '.search-toggle');

        $(document).on({
            click: $.proxy(Site, 'checkSearchForm')
        }, '.search-form button[type="submit"]');

        $(document).on({
            click: $.proxy(Site, 'toggleNav')
        }, '.nav-toggle');

        $(document).on({
            click: $.proxy(Site, 'toggleSubNav')
        }, '.has-subnav .toggle-subnav');

        $(document).on({
            click: $.proxy(Site, 'scrollToTop')
        }, '.btn-top');

        $(document).on({
            click: $.proxy(Site, 'onToggleNetwork')
        }, '.network--toggle, .network--block');

        $(document).on({
            click: $.proxy(Site, 'toggleSocialShare')
        }, '.social-share--toggle, .social-share--close');

        $(document).on({
            click: $.proxy(Site, 'copyToClipboard')
        }, '.social-share--url input');

        $(document).on({
            keyup: $.proxy(Site, 'validateBirthdayInput')
        }, '.birthday_datepicker');

        $(document).on({
            click: $.proxy(Site, 'closePopup')
        }, '.popup--close');

        $(document).on({
            click: $.proxy(Site, 'openZenderWindow')
        }, '.btn--zenderview');

        $(document).on({
            click: $.proxy(Site, 'closeZenderWindow')
        }, '.channeloverview .btn--close');

        $(document).on({
            click: $.proxy(Site, 'moreInfoZender')
        }, '[data-products]');

        $(document).on({
            click: $.proxy(Site, 'onToggleFaq')
        }, '.faq--question .faq--question__title a');

        $(document).on({
            keyup: $.proxy(Site, 'faqFilterResults')
        }, '[data-faq-filter]');

        $(document).on({
            click: $.proxy(Site, 'faqClearSearch')
        }, '[data-faq-search-clear]');

        $(document).on({
            click: $.proxy(Site, 'toggleHighlight'),
            touchend: $.proxy(Site, 'toggleHighlight')
        }, '.highlight--block');

        $(document).on({
            click: $.proxy(Site, 'toggleHighlightStopPropagation'),
            touchend: $.proxy(Site, 'toggleHighlightStopPropagation')
        }, '.highlight--block a');

        $(document).on({
            click: $.proxy(Site, 'toggleChannelDescription')
        }, '.channel-info-data');

        $(document).on({
            click: $.proxy(Site, 'openChat')
        }, '[data-chat]');

        $(document).on({
            keyup: $.proxy(Site, 'updateZipcode')
        }, '[data-autofill-zipcode]');

        $(document).on({
            change: $.proxy(Site, 'updateAddress')
        }, '[name="street"], [name="city"]');

        $(document).on({
            change: $.proxy(Site, 'changeContactMethod')
        }, '[data-contact-method="select"]');

        $(document).on({
            change: $.proxy(Site, 'autofillAddress')
        }, Site.$autofillInitField);

        $(document).on({
            keyup: $.proxy(Site, 'autofillAddress')
        }, Site.$autofillInitField);

        $(document).on({
            change: $.proxy(Site, 'filterProducts')
        }, Site.$filterCheckboxes);

        $(document).on({
            change: $.proxy(Site, 'filterAddons')
        }, Site.$filterAddonsCheck);

        $(document).on({
            change: $.proxy(Site, 'filterPackage')
        }, '#viewchannels');

        $(document).on({
            click: $.proxy(Site, 'onToggleConfirmFlow')
        }, '[data-confirm-flow-toggle]');

        $(document).on({
            change: $.proxy(Site, 'checkKnid')
        }, Site.$knidInitField);

        $(document).on({
            change: $.proxy(Site, 'validatePhoneInput')
        }, 'input[type="tel"]');

        window.onload = function () {
            Site.onPageLoad();
        };
    }(jQuery)
);
